import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import TestesStackbannerads from "./TestesStackbannerads";
import React from "react";

export default function TESTESPage() {
  return (
    <>
      <Helmet>
        <title>Cainan Lucas Arajo&#39;s Application9</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full flex-col gap-[30px] bg-white-a700">
        <Header />
        <TestesStackbannerads />
      </div>
    </>
  );
}
