import { Text, Button, Img } from "./..";
import React from "react";

export default function UserProfile3({
  profilePictureText = "Foto de Perfil",
  choosePhotoButtonText = "Escolher foto",
  noFileChosenText = "Nenhum arquivo escolhido",
  ...props
}) {
  return (
    <div {...props} className={`${props.className} flex flex-col items-start w-[50%] md:w-full gap-5`}>
      <Text as="p" className="text-[18px] font-normal tracking-[0.18px] text-blue_gray-900_01">
        {profilePictureText}
      </Text>
      <div className="flex items-center gap-3.5 self-stretch">
        <Img src="images/img_thumbs_up.svg" alt="Image" className="h-[32px]" />
        <Button
          color="blue_gray_900_01"
          variant="fill"
          shape="round"
          className="min-w-[150px] rounded-[10px] px-4 italic tracking-[0.18px]"
        >
          {choosePhotoButtonText}
        </Button>
        <Text as="p" className="text-[18px] font-normal tracking-[0.18px] text-blue_gray-900_01">
          {noFileChosenText}
        </Text>
      </div>
    </div>
  );
}
