import React from "react";
import { Link } from "react-router-dom";
const Home = () => {
  return (
    <div style={{ height: "100vh", padding: "20px", backgroundColor: "#232323", color: "white" }}>
      <h1 style={{ fontSize: "48px", marginBottom: "20px" }}>Homepage</h1>
      <p style={{ fontSize: "18px", marginBottom: "30px" }}>
        This project was generated By{" "}
        <a href="https://www.dhiwise.com" style={{ color: "#87CEFA", textDecoration: "none" }}>
          Dhiwise
        </a>
        . Quickly use below links to navigate through all pages.
      </p>
      <ul style={{ listStyle: "none", padding: "0" }}>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/capa" style={{ color: "#87CEFA", textDecoration: "none" }}>
            CAPA
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/experinciadocliente" style={{ color: "#87CEFA", textDecoration: "none" }}>
            ExperinciadoCliente
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/testes" style={{ color: "#87CEFA", textDecoration: "none" }}>
            TESTES
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/testesone" style={{ color: "#87CEFA", textDecoration: "none" }}>
            TESTESOne
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/testestwo" style={{ color: "#87CEFA", textDecoration: "none" }}>
            TESTESTwo
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/instagrampostone" style={{ color: "#87CEFA", textDecoration: "none" }}>
            InstagrampostOne
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/instagrampostoneone" style={{ color: "#87CEFA", textDecoration: "none" }}>
            InstagrampostOneOne
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/frameone" style={{ color: "#87CEFA", textDecoration: "none" }}>
            FrameOne
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/desktop" style={{ color: "#87CEFA", textDecoration: "none" }}>
            Desktop
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/framefive" style={{ color: "#87CEFA", textDecoration: "none" }}>
            FrameFive
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/framefour" style={{ color: "#87CEFA", textDecoration: "none" }}>
            FrameFour
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/frametwo" style={{ color: "#87CEFA", textDecoration: "none" }}>
            FrameTwo
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/framethree" style={{ color: "#87CEFA", textDecoration: "none" }}>
            FrameThree
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/frameoneone" style={{ color: "#87CEFA", textDecoration: "none" }}>
            FrameOneOne
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/desktwo" style={{ color: "#87CEFA", textDecoration: "none" }}>
            DeskTwo
          </Link>
        </li>
      </ul>
    </div>
  );
};
export default Home;
