import { Img } from "./..";
import React from "react";
import { MenuItem, Menu, Sidebar, sidebarClasses } from "react-pro-sidebar";

export default function Sidebar1({ ...props }) {
  const [collapsed, setCollapsed] = React.useState(false);

  //use this function to collapse/expand the sidebar
  //function collapseSidebar() {
  //    setCollapsed(!collapsed)
  //}

  return (
    <Sidebar
      {...props}
      width="208px !important"
      collapsedWidth="80px !important"
      collapsed={collapsed}
      className={`${props.className} flex flex-col h-screen mt-[116px] top-0 !sticky overflow-auto md:hidden`}
    >
      <Menu
        menuItemStyles={{
          button: {
            padding: 0,
            paddingTop: "22px",
            paddingBottom: "22px",
          },
          label: { display: "none" },
        }}
        rootStyles={{ ["&>ul"]: { gap: "1.00px" } }}
        className="flex w-full flex-col items-center self-stretch"
      >
        <MenuItem icon={<Img src="images/img_close.svg" alt="Close" className="h-[44px] w-full" />} />
        <MenuItem icon={<Img src="images/img_user.svg" alt="User" className="h-[44px] w-full" />} />
        <MenuItem icon={<Img src="images/img_user_deep_purple_a200_01.svg" alt="User" className="h-[44px] w-full" />} />
        <MenuItem
          icon={<Img src="images/img_user_deep_purple_a200_01_44x44.svg" alt="User" className="h-[44px] w-full" />}
        />
        <MenuItem icon={<Img src="images/img_group_30.svg" alt="Image" className="h-[44px] w-full" />} />
        <MenuItem icon={<Img src="images/img_floating_icon.svg" alt="Floatingicon" className="h-[44px] w-full" />} />
      </Menu>
    </Sidebar>
  );
}
