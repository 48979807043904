import { Button, Img, Heading, Slider, Text } from "../../components";
import React from "react";

export default function DesktwoRowOne() {
  const [sliderState, setSliderState] = React.useState(0);
  const sliderRef = React.useRef(null);

  return (
    <div className="flex items-start self-stretch md:flex-col">
      <div className="flex h-[30px] w-[6%] items-center justify-center bg-[url(/public/images/img_group_14.png)] bg-cover bg-no-repeat py-1 lg:h-auto md:h-auto md:w-full">
        <div className="flex w-full flex-wrap justify-center">
          <Text
            size="textlg"
            as="p"
            className="text-shadow-ts1 font-outfit text-[15.13px] font-light tracking-[0.15px] text-black-900_01"
          >
            HOT
          </Text>
          <Text
            size="textlg"
            as="p"
            className="text-shadow-ts1 font-outfit text-[15.13px] font-light tracking-[0.15px] text-white-a700"
          >
            PLACE
          </Text>
        </div>
      </div>
      <div className="flex w-[95%] items-start justify-between gap-[108px] self-center md:w-full md:flex-col md:gap-5">
        <div className="container-xs relative mt-[50px] h-[1236px] content-center self-center lg:h-auto lg:px-5 md:mt-0 md:h-auto md:px-5">
          <div className="mx-auto flex w-full flex-col">
            <Slider
              autoPlay
              autoPlayInterval={2000}
              responsive={{ 0: { items: 1 }, 551: { items: 1 }, 1051: { items: 1 }, 1441: { items: 1 } }}
              disableDotsControls
              activeIndex={sliderState}
              onSlideChanged={(e) => {
                setSliderState(e?.item);
              }}
              ref={sliderRef}
              items={[...Array(3)].map(() => (
                <React.Fragment key={Math.random()}>
                  <div className="flex flex-col self-stretch rounded-[112px] bg-gradient3 shadow-4xl">
                    <div className="mb-10 flex flex-col items-center">
                      <div className="relative h-[1046px] self-stretch">
                        <div className="absolute left-0 right-0 top-0 m-auto flex-1">
                          <Img
                            src="images/img_mulher_com_cabe.png"
                            alt="Mulhercomcabe"
                            className="h-[934px] w-full rounded-[12px] object-cover lg:h-auto md:h-auto"
                          />
                        </div>
                        <Img
                          src="images/img_mulher_com_cabe_230x230.png"
                          alt="Mulhercomcabe"
                          className="absolute bottom-[1.84px] left-0 right-0 m-auto h-[230px] w-[230px] rounded-[114px] object-cover"
                        />
                      </div>
                      <div className="ml-24 mr-[130px] flex items-start justify-center self-stretch md:mx-0 md:flex-col">
                        <div className="mt-2 flex flex-1 items-center gap-4 md:self-stretch">
                          <Img src="images/img_linkedin.svg" alt="Linkedin" className="h-[28px]" />
                          <Button
                            color="blue_gray_100"
                            size="lg"
                            variant="fill"
                            className="min-w-[118px] rounded-[26px] px-[30px] sm:px-4"
                          >
                            Brasil
                          </Button>
                        </div>
                        <Heading
                          size="heading6xl"
                          as="h1"
                          className="self-end text-[44.05px] font-bold text-white-a700 lg:text-[37px] md:self-auto md:text-[28px] sm:text-[22px]"
                        >
                          Maria Helena
                        </Heading>
                        <Img
                          src="images/img_favorite_blue_gray_100.svg"
                          alt="Favorite"
                          className="mb-4 ml-[244px] h-[68px] w-[10%] object-contain md:ml-0 md:w-full"
                        />
                      </div>
                      <div className="mt-[26px] flex items-center gap-2">
                        <Img src="images/img_vector_32x38.png" alt="Vector" className="h-[32px] object-cover" />
                        <Heading
                          size="heading2xl"
                          as="h2"
                          className="text-[26.35px] font-bold text-blue_gray-100 lg:text-[22px]"
                        >
                          310
                        </Heading>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            />
          </div>
          <div className="absolute left-0 right-0 top-[36%] m-auto flex flex-1 justify-between gap-5 px-[88px] lg:px-8 md:px-5 sm:px-4">
            <Button
              size="xl"
              shape="square"
              onClick={() => {
                sliderRef?.current?.slidePrev();
              }}
              className="w-[66px]"
            >
              <Img src="images/img_arrow_down_blue_gray_800.svg" />
            </Button>
            <Button
              size="xl"
              shape="square"
              onClick={() => {
                sliderRef?.current?.slideNext();
              }}
              className="w-[66px] rotate-[-180deg]"
            >
              <Img src="images/img_arrow_down_blue_gray_800.svg" />
            </Button>
          </div>
        </div>
        <Button
          shape="round"
          color="purple_A200_cyan_A100"
          className="min-w-[170px] rounded-[10px] px-[30px] font-bold tracking-[0.16px] sm:px-4"
        >
          Seja Premium
        </Button>
      </div>
    </div>
  );
}
