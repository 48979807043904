import { Img, Text, Heading } from "../../components";
import UserProfile from "../../components/UserProfile";
import React, { Suspense } from "react";

const data = [
  { userImage: "images/img_image_1.png", userName: "Andry Silva", userScore: "48.509" },
  { userImage: "images/img_image_2.png", userName: "Debora Peixoto", userScore: "45.181" },
  { userImage: "images/img_image_1.png", userName: "Andry Silva", userScore: "48.509" },
  { userImage: "images/img_image_4.png", userName: "Xehli G", userScore: "41.538" },
];

export default function TestesStackbannerads() {
  return (
    <div className="relative mb-1 h-[1816px] px-[336px] lg:px-8 md:px-5 sm:px-4">
      <div className="absolute left-0 right-0 top-[39%] m-auto flex flex-1 bg-blue_gray-100_33 py-[58px] md:py-5 sm:py-4">
        <div className="container-xs flex justify-center px-14 lg:px-5 md:px-5">
          <Heading
            size="heading5xl"
            as="h1"
            className="text-[38.08px] font-bold tracking-[0.38px] text-gray-400_33 lg:text-[32px] md:text-[32px] sm:text-[30px]"
          >
            BANNER ADS
          </Heading>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 top-0 m-auto flex h-max flex-1 flex-col items-center">
        <Img
          src="images/img_camada_2.svg"
          alt="Camadatwo"
          className="relative z-[2] h-[128px] w-[16%] object-contain"
        />
        <div className="relative mt-[-46px] self-stretch">
          <div className="relative z-[1] flex flex-col items-start">
            <div className="flex flex-col items-start self-stretch">
              <Img
                src="images/img_image.png"
                alt="Image"
                className="h-[200px] w-[24%] rounded-tl-[30px] rounded-tr-[30px] object-contain opacity-20"
              />
              <Img
                src="images/img_image_60x60.png"
                alt="Image"
                className="relative ml-11 mt-[-8px] h-[60px] w-[60px] rounded-[30px] object-cover opacity-20 md:ml-0"
              />
            </div>
            <div className="relative mt-[-50px] flex w-[24%] justify-end rounded-bl-[28px] rounded-br-[28px] bg-blue_gray-50_33 px-[52px] py-2.5 lg:w-full md:w-full md:px-5 sm:px-4">
              <div className="mb-1.5 flex w-[66%] flex-col items-center lg:w-full md:w-full">
                <Heading
                  as="h2"
                  className="text-[17px] font-bold tracking-[0.17px] text-blue_gray-900_33_01 lg:text-[14px]"
                >
                  Morena Mineira
                </Heading>
                <div className="flex items-center self-stretch">
                  <Img src="images/img_fi_535234_blue_500.svg" alt="Fi535234" className="h-[12px] w-[12px]" />
                  <Text
                    size="texts"
                    as="p"
                    className="text-[11px] font-normal tracking-[0.11px] text-blue_gray-900_33_01"
                  >
                    76.411
                  </Text>
                </div>
              </div>
            </div>
          </div>
          <div className="relative mt-[-114px] flex items-center md:flex-col">
            <Img
              src="images/img_image.png"
              alt="Image"
              className="mb-[576px] h-[200px] w-[20%] self-end rounded-tl-[30px] rounded-tr-[30px] object-contain opacity-20 md:w-full md:self-auto"
            />
            <div className="relative ml-[-192px] h-[1500px] flex-1 md:ml-0 md:w-full md:flex-none md:self-stretch">
              <Img
                src="images/img_main.png"
                alt="Main"
                className="absolute bottom-0 left-0 top-0 my-auto h-[1500px] w-[90%] object-contain"
              />
              <div className="absolute bottom-0 left-0 right-0 top-0 mx-1 my-auto flex h-max flex-1 items-start gap-[62px] lg:mx-0 md:relative md:mx-0 md:flex-col">
                <Img
                  src="images/img_iphone_1.png"
                  alt="Iphoneone"
                  className="h-[1290px] w-[44%] object-contain md:w-full"
                />
                <Img
                  src="images/img_iphone_2.png"
                  alt="Iphonetwo"
                  className="mt-[172px] h-[1290px] w-[44%] self-end object-contain md:mt-0 md:w-full md:self-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-[16%] left-0 right-0 m-auto flex flex-1 flex-col items-start">
        <div className="flex flex-col items-start self-stretch">
          <Img
            src="images/img_image_200x300.png"
            alt="Image"
            className="h-[200px] w-[24%] rounded-tl-[30px] rounded-tr-[30px] object-contain opacity-20"
          />
          <Img
            src="images/img_image_1.png"
            alt="Image"
            className="relative ml-11 mt-[-8px] h-[60px] w-[60px] rounded-[30px] object-cover opacity-20 md:ml-0"
          />
        </div>
        <div className="relative mt-[-50px] flex w-[24%] justify-center rounded-bl-[28px] rounded-br-[28px] bg-blue_gray-50_33 p-2.5 lg:w-full md:w-full">
          <div className="mb-2 flex w-[34%] flex-col items-center lg:w-full md:w-full">
            <Heading
              as="h2"
              className="text-[17px] font-bold tracking-[0.17px] text-blue_gray-900_33_01 lg:text-[14px]"
            >
              Andry Silva
            </Heading>
            <div className="flex items-center self-stretch">
              <Img src="images/img_fi_535234_blue_500.svg" alt="Fi535234" className="h-[12px] w-[12px] self-end" />
              <Text size="texts" as="p" className="text-[11px] font-normal tracking-[0.11px] text-blue_gray-900_33_01">
                48.509
              </Text>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute left-0 right-0 top-[21%] m-auto flex flex-1 flex-col items-start">
        <div className="flex flex-col items-start self-stretch">
          <Img
            src="images/img_image_200x300.png"
            alt="Image Eleven"
            className="h-[200px] w-[24%] rounded-tl-[30px] rounded-tr-[30px] object-contain opacity-20"
          />
          <Img
            src="images/img_image_1.png"
            alt="Image Thirteen"
            className="relative ml-11 mt-[-8px] h-[60px] w-[60px] rounded-[30px] object-cover opacity-20 md:ml-0"
          />
        </div>
        <div className="relative mt-[-50px] flex w-[24%] justify-center rounded-bl-[28px] rounded-br-[28px] bg-blue_gray-50_33 p-2.5 lg:w-full md:w-full">
          <div className="mb-2 flex w-[34%] flex-col items-center lg:w-full md:w-full">
            <Heading
              as="h2"
              className="text-[17px] font-bold tracking-[0.17px] text-blue_gray-900_33_01 lg:text-[14px]"
            >
              Andry Silva
            </Heading>
            <div className="flex items-center self-stretch">
              <Img src="images/img_fi_535234_blue_500.svg" alt="Fi535234" className="h-[12px] w-[12px] self-end" />
              <Text size="texts" as="p" className="text-[11px] font-normal tracking-[0.11px] text-blue_gray-900_33_01">
                48.509
              </Text>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 m-auto flex flex-1 flex-col items-start">
        <Img
          src="images/img_image_200x300.png"
          alt="Image Fifteen"
          className="h-[200px] w-[24%] rounded-tl-[30px] rounded-tr-[30px] object-contain opacity-20"
        />
        <div className="relative mt-[-8px] flex gap-4 self-stretch md:flex-col">
          <Suspense fallback={<div>Loading feed...</div>}>
            {data.map((d, index) => (
              <UserProfile {...d} key={"testes" + index} />
            ))}
          </Suspense>
        </div>
      </div>
      <div className="absolute bottom-[33%] left-0 right-0 m-auto flex w-[48%] flex-col items-start">
        <Img
          src="images/img_image_5.png"
          alt="Imageteen"
          className="h-[200px] w-[48%] rounded-tl-[30px] rounded-tr-[30px] object-contain opacity-20"
        />
        <div className="flex w-[48%] justify-end rounded-bl-[28px] rounded-br-[28px] bg-blue_gray-50_33 py-2.5 pl-14 pr-16 lg:w-full lg:pr-8 md:w-full md:px-5 sm:px-4">
          <div className="mb-1.5 flex w-[66%] flex-col items-center lg:w-full md:w-full">
            <Heading
              as="h2"
              className="text-[17px] font-bold tracking-[0.17px] text-blue_gray-900_33_01 lg:text-[14px]"
            >
              Helena Filmes
            </Heading>
            <div className="flex items-center self-stretch">
              <Img src="images/img_fi_535234_blue_500.svg" alt="Fi535234" className="h-[12px] w-[12px]" />
              <Text size="texts" as="p" className="text-[11px] font-normal tracking-[0.11px] text-blue_gray-900_33_01">
                66.403
              </Text>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute left-0 right-0 top-[5%] z-[8] m-auto flex w-[48%] flex-col items-start">
        <div className="flex flex-col items-start self-stretch">
          <Img
            src="images/img_image_5.png"
            alt="Imageteen"
            className="h-[200px] w-[48%] rounded-tl-[30px] rounded-tr-[30px] object-contain opacity-20"
          />
          <Img
            src="images/img_image_6.png"
            alt="Image Twentyone"
            className="relative ml-11 mt-[-8px] h-[60px] w-[60px] rounded-[30px] object-cover opacity-20 md:ml-0"
          />
        </div>
        <div className="relative mt-[-50px] flex w-[48%] justify-end rounded-bl-[28px] rounded-br-[28px] bg-blue_gray-50_33 py-2.5 pl-14 pr-16 lg:w-full lg:pr-8 md:w-full md:px-5 sm:px-4">
          <div className="mb-1.5 flex w-[66%] flex-col items-center lg:w-full md:w-full">
            <Heading
              as="h2"
              className="text-[17px] font-bold tracking-[0.17px] text-blue_gray-900_33_01 lg:text-[14px]"
            >
              Helena Filmes
            </Heading>
            <div className="flex items-center self-stretch">
              <Img src="images/img_fi_535234_blue_500.svg" alt="Fi535234 Eleven" className="h-[12px] w-[12px]" />
              <Text size="texts" as="p" className="text-[11px] font-normal tracking-[0.11px] text-blue_gray-900_33_01">
                66.403
              </Text>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-[16%] left-0 right-0 m-auto flex w-[48%] flex-col items-start">
        <Img
          src="images/img_image_7.png"
          alt="Image"
          className="h-[200px] w-[48%] rounded-tl-[30px] rounded-tr-[30px] object-contain opacity-20"
        />
        <div className="flex w-[48%] justify-end rounded-bl-[28px] rounded-br-[28px] bg-blue_gray-50_33 px-[54px] py-2.5 lg:w-full md:w-full md:px-5 sm:px-4">
          <div className="mb-2 flex w-[68%] flex-col items-center lg:w-full md:w-full">
            <Heading
              as="h2"
              className="text-[17px] font-bold tracking-[0.17px] text-blue_gray-900_33_01 lg:text-[14px]"
            >
              Debora Peixoto
            </Heading>
            <div className="flex items-center self-stretch">
              <Img src="images/img_fi_535234_blue_500.svg" alt="Fi535234" className="h-[12px] w-[12px] self-end" />
              <Text size="texts" as="p" className="text-[11px] font-normal tracking-[0.11px] text-blue_gray-900_33_01">
                45.181
              </Text>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute left-0 right-0 top-[21%] m-auto flex w-[48%] flex-col items-start">
        <Img
          src="images/img_image_7.png"
          alt="Image"
          className="h-[200px] w-[48%] rounded-tl-[30px] rounded-tr-[30px] object-contain opacity-20"
        />
        <div className="flex w-[48%] justify-end rounded-bl-[28px] rounded-br-[28px] bg-blue_gray-50_33 px-[54px] py-2.5 lg:w-full md:w-full md:px-5 sm:px-4">
          <div className="mb-2 flex w-[68%] flex-col items-center lg:w-full md:w-full">
            <Heading
              as="h2"
              className="text-[17px] font-bold tracking-[0.17px] text-blue_gray-900_33_01 lg:text-[14px]"
            >
              Debora Peixoto
            </Heading>
            <div className="flex items-center self-stretch">
              <Img src="images/img_fi_535234_blue_500.svg" alt="Fi535234" className="h-[12px] w-[12px] self-end" />
              <Text size="texts" as="p" className="text-[11px] font-normal tracking-[0.11px] text-blue_gray-900_33_01">
                45.181
              </Text>
            </div>
          </div>
        </div>
      </div>
      <Img
        src="images/img_image_7.png"
        alt="Image"
        className="absolute bottom-[4%] left-[34%] z-[9] m-auto h-[200px] w-[32%] rounded-tl-[30px] rounded-tr-[30px] object-contain opacity-20"
      />
      <div className="absolute bottom-[33%] left-0 right-0 m-auto flex w-[48%] flex-col items-end">
        <Img
          src="images/img_image_8.png"
          alt="Image"
          className="h-[200px] w-[48%] rounded-tl-[30px] rounded-tr-[30px] object-contain opacity-20"
        />
        <div className="flex w-[48%] justify-end rounded-bl-[28px] rounded-br-[28px] bg-blue_gray-50_33 px-[30px] py-2.5 lg:w-full md:w-full sm:px-4">
          <div className="mb-1.5 flex w-[64%] flex-col items-center lg:w-full md:w-full">
            <Heading
              as="h2"
              className="text-[17px] font-bold tracking-[0.17px] text-blue_gray-900_33_01 lg:text-[14px]"
            >
              Fernanda Campos
            </Heading>
            <div className="flex items-center self-stretch">
              <Img src="images/img_fi_535234_blue_500.svg" alt="Fi535234" className="h-[12px] w-[12px]" />
              <Text size="texts" as="p" className="text-[11px] font-normal tracking-[0.11px] text-blue_gray-900_33_01">
                62.781
              </Text>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute left-0 right-0 top-[5%] z-10 m-auto flex w-[48%] flex-col items-end">
        <div className="flex flex-col items-end self-stretch">
          <Img
            src="images/img_image_8.png"
            alt="Image Thirtyone"
            className="h-[200px] w-[48%] rounded-tl-[30px] rounded-tr-[30px] object-contain opacity-20"
          />
          <Img
            src="images/img_image_9.png"
            alt="Image"
            className="relative mr-[194px] mt-[-8px] h-[60px] w-[60px] rounded-[30px] object-cover opacity-20 md:mr-0"
          />
        </div>
        <div className="relative mt-[-50px] flex w-[48%] justify-end rounded-bl-[28px] rounded-br-[28px] bg-blue_gray-50_33 px-[30px] py-2.5 lg:w-full md:w-full sm:px-4">
          <div className="mb-1.5 flex w-[64%] flex-col items-center lg:w-full md:w-full">
            <Heading
              as="h2"
              className="text-[17px] font-bold tracking-[0.17px] text-blue_gray-900_33_01 lg:text-[14px]"
            >
              Fernanda Campos
            </Heading>
            <div className="flex items-center self-stretch">
              <Img src="images/img_fi_535234_blue_500.svg" alt="Fi535234" className="h-[12px] w-[12px]" />
              <Text size="texts" as="p" className="text-[11px] font-normal tracking-[0.11px] text-blue_gray-900_33_01">
                62.781
              </Text>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-[16%] left-0 right-0 m-auto flex w-[48%] flex-col items-end">
        <Img
          src="images/img_image_10.png"
          alt="Image"
          className="h-[200px] w-[48%] rounded-tl-[30px] rounded-tr-[30px] object-contain opacity-20"
        />
        <div className="flex w-[48%] justify-end rounded-bl-[28px] rounded-br-[28px] bg-blue_gray-50_33 px-[50px] py-2.5 lg:w-full md:w-full md:px-5 sm:px-4">
          <div className="mb-2 flex w-[66%] flex-col items-center lg:w-full md:w-full">
            <Heading
              as="h2"
              className="text-[17px] font-bold tracking-[0.17px] text-blue_gray-900_33_01 lg:text-[14px]"
            >
              Victoria Matosa
            </Heading>
            <div className="flex items-center self-stretch">
              <Img src="images/img_fi_535234_blue_500.svg" alt="Fi535234" className="h-[12px] w-[12px] self-end" />
              <Text size="texts" as="p" className="text-[11px] font-normal tracking-[0.11px] text-blue_gray-900_33_01">
                41.974
              </Text>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute left-0 right-0 top-[21%] m-auto flex w-[48%] flex-col items-end">
        <Img
          src="images/img_image_10.png"
          alt="Image"
          className="h-[200px] w-[48%] rounded-tl-[30px] rounded-tr-[30px] object-contain opacity-20"
        />
        <div className="flex w-[48%] justify-end rounded-bl-[28px] rounded-br-[28px] bg-blue_gray-50_33 px-[50px] py-2.5 lg:w-full md:w-full md:px-5 sm:px-4">
          <div className="mb-2 flex w-[66%] flex-col items-center lg:w-full md:w-full">
            <Heading
              as="h2"
              className="text-[17px] font-bold tracking-[0.17px] text-blue_gray-900_33_01 lg:text-[14px]"
            >
              Victoria Matosa
            </Heading>
            <div className="flex items-center self-stretch">
              <Img src="images/img_fi_535234_blue_500.svg" alt="Fi535234" className="h-[12px] w-[12px] self-end" />
              <Text size="texts" as="p" className="text-[11px] font-normal tracking-[0.11px] text-blue_gray-900_33_01">
                41.974
              </Text>
            </div>
          </div>
        </div>
      </div>
      <Img
        src="images/img_image_10.png"
        alt="Image"
        className="absolute bottom-[4%] right-[34%] z-[11] m-auto h-[200px] w-[32%] rounded-tl-[30px] rounded-tr-[30px] object-contain opacity-20"
      />
      <div className="absolute bottom-[33%] left-0 right-0 m-auto flex flex-1 flex-col items-end">
        <Img
          src="images/img_image_11.png"
          alt="Image Fortyone"
          className="h-[200px] w-[24%] rounded-tl-[30px] rounded-tr-[30px] object-contain opacity-20"
        />
        <div className="flex w-[24%] justify-center rounded-bl-[28px] rounded-br-[28px] bg-blue_gray-50_33 p-2.5 lg:w-full md:w-full">
          <div className="mb-1.5 flex w-[28%] flex-col items-center lg:w-full md:w-full">
            <Heading
              as="h2"
              className="text-[17px] font-bold tracking-[0.17px] text-blue_gray-900_33_01 lg:text-[14px]"
            >
              Andressa
            </Heading>
            <div className="flex items-center self-stretch">
              <Img src="images/img_fi_535234_blue_500.svg" alt="Fi535234" className="h-[12px] w-[12px]" />
              <Text size="texts" as="p" className="text-[11px] font-normal tracking-[0.11px] text-blue_gray-900_33_01">
                64.060
              </Text>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute left-0 right-0 top-[5%] z-[12] m-auto h-[264px] flex-1 content-center lg:h-auto md:h-auto">
        <div className="mx-auto flex flex-1 flex-col items-end">
          <Img
            src="images/img_image_11.png"
            alt="Image"
            className="h-[200px] w-[24%] rounded-tl-[30px] rounded-tr-[30px] object-contain opacity-20"
          />
          <div className="flex w-[24%] justify-center rounded-bl-[28px] rounded-br-[28px] bg-blue_gray-50_33 p-2.5 lg:w-full md:w-full">
            <div className="mb-1.5 flex w-[28%] flex-col items-center lg:w-full md:w-full">
              <Heading
                as="h2"
                className="text-[17px] font-bold tracking-[0.17px] text-blue_gray-900_33_01 lg:text-[14px]"
              >
                Andressa
              </Heading>
              <div className="flex items-center self-stretch">
                <Img src="images/img_fi_535234_blue_500.svg" alt="Fi535234" className="h-[12px] w-[12px]" />
                <Text
                  size="texts"
                  as="p"
                  className="text-[11px] font-normal tracking-[0.11px] text-blue_gray-900_33_01"
                >
                  64.060
                </Text>
              </div>
            </div>
          </div>
        </div>
        <Img
          src="images/img_image_12.png"
          alt="Image Fortyfive"
          className="absolute bottom-[13px] right-[16%] m-auto h-[60px] w-[60px] rounded-[30px] object-cover opacity-20"
        />
      </div>
      <div className="absolute bottom-[16%] left-0 right-0 m-auto flex flex-1 flex-col items-end">
        <Img
          src="images/img_image_13.png"
          alt="Image"
          className="h-[200px] w-[24%] rounded-tl-[30px] rounded-tr-[30px] object-contain opacity-20"
        />
        <div className="flex w-[24%] justify-center rounded-bl-[28px] rounded-br-[28px] bg-blue_gray-50_33 p-2.5 lg:w-full md:w-full">
          <div className="mb-2 flex w-[24%] flex-col items-start lg:w-full md:w-full">
            <Heading
              as="h2"
              className="text-[17px] font-bold tracking-[0.17px] text-blue_gray-900_33_01 lg:text-[14px]"
            >
              Xehli G
            </Heading>
            <div className="flex items-center justify-center self-stretch">
              <Img src="images/img_fi_535234_blue_500.svg" alt="Fi535234" className="h-[12px] w-[12px] self-end" />
              <Text size="texts" as="p" className="text-[11px] font-normal tracking-[0.11px] text-blue_gray-900_33_01">
                41.538
              </Text>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute left-0 right-0 top-[21%] m-auto flex flex-1 flex-col items-end">
        <Img
          src="images/img_image_13.png"
          alt="Image Fortynine"
          className="h-[200px] w-[24%] rounded-tl-[30px] rounded-tr-[30px] object-contain opacity-20"
        />
        <div className="flex w-[24%] justify-center rounded-bl-[28px] rounded-br-[28px] bg-blue_gray-50_33 p-2.5 lg:w-full md:w-full">
          <div className="mb-2 flex w-[24%] flex-col items-start lg:w-full md:w-full">
            <Heading
              as="h2"
              className="text-[17px] font-bold tracking-[0.17px] text-blue_gray-900_33_01 lg:text-[14px]"
            >
              Xehli G
            </Heading>
            <div className="flex items-center justify-center self-stretch">
              <Img src="images/img_fi_535234_blue_500.svg" alt="Fi535234" className="h-[12px] w-[12px] self-end" />
              <Text size="texts" as="p" className="text-[11px] font-normal tracking-[0.11px] text-blue_gray-900_33_01">
                41.538
              </Text>
            </div>
          </div>
        </div>
      </div>
      <Img
        src="images/img_image_13.png"
        alt="Image Fiftyone"
        className="absolute bottom-[4%] right-[18%] z-[13] m-auto h-[200px] w-[24%] rounded-tl-[30px] rounded-tr-[30px] object-contain opacity-20"
      />
      <div className="absolute bottom-[33%] left-0 right-0 m-auto flex flex-1 flex-col items-start">
        <Img
          src="images/img_image_60x60.png"
          alt="Image"
          className="relative z-[7] ml-11 h-[60px] w-[60px] rounded-[30px] object-cover opacity-20 md:ml-0"
        />
        <div className="relative mt-[-50px] flex w-[24%] justify-end rounded-bl-[28px] rounded-br-[28px] bg-blue_gray-50_33 px-[52px] py-2.5 lg:w-full md:w-full md:px-5 sm:px-4">
          <div className="mb-1.5 flex w-[66%] flex-col items-center lg:w-full md:w-full">
            <Heading
              as="h2"
              className="text-[17px] font-bold tracking-[0.17px] text-blue_gray-900_33_01 lg:text-[14px]"
            >
              Morena Mineira
            </Heading>
            <div className="flex items-center self-stretch">
              <Img src="images/img_fi_535234_blue_500.svg" alt="Fi535234" className="h-[12px] w-[12px]" />
              <Text size="texts" as="p" className="text-[11px] font-normal tracking-[0.11px] text-blue_gray-900_33_01">
                76.411
              </Text>
            </div>
          </div>
        </div>
      </div>
      <Img
        src="images/img_image_6.png"
        alt="Image Fiftyfive"
        className="absolute bottom-[33%] left-[36%] m-auto h-[60px] w-[60px] rounded-[30px] object-cover opacity-20"
      />
      <Img
        src="images/img_image_2.png"
        alt="Image"
        className="absolute bottom-[17%] left-[36%] m-auto h-[60px] w-[60px] rounded-[30px] object-cover opacity-20"
      />
      <Img
        src="images/img_image_2.png"
        alt="Image Fiftynine"
        className="absolute left-[36%] top-[31%] m-auto h-[60px] w-[60px] rounded-[30px] object-cover opacity-20"
      />
      <Img
        src="images/img_image_9.png"
        alt="Imagetyone"
        className="absolute bottom-[33%] right-[44%] m-auto h-[60px] w-[60px] rounded-[30px] object-cover opacity-20"
      />
      <Img
        src="images/img_image_3.png"
        alt="Image"
        className="absolute bottom-[17%] right-[44%] m-auto h-[60px] w-[60px] rounded-[30px] object-cover opacity-20"
      />
      <Img
        src="images/img_image_3.png"
        alt="Imagetyfive"
        className="absolute right-[44%] top-[31%] m-auto h-[60px] w-[60px] rounded-[30px] object-cover opacity-20"
      />
      <Img
        src="images/img_image_12.png"
        alt="Image"
        className="absolute bottom-[33%] right-[28%] m-auto h-[60px] w-[60px] rounded-[30px] object-cover opacity-20"
      />
      <Img
        src="images/img_image_4.png"
        alt="Imagetynine"
        className="absolute bottom-[17%] right-[28%] m-auto h-[60px] w-[60px] rounded-[30px] object-cover opacity-20"
      />
      <Img
        src="images/img_image_4.png"
        alt="Image"
        className="absolute right-[28%] top-[31%] m-auto h-[60px] w-[60px] rounded-[30px] object-cover opacity-20"
      />
    </div>
  );
}
