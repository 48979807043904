import { Helmet } from "react-helmet";
import { Text, Img, Heading } from "../../components";
import React from "react";

export default function CAPAPage() {
  return (
    <>
      <Helmet>
        <title>Cainan Lucas Arajo&#39;s Application9</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full items-center justify-between gap-5 bg-gray-900 md:flex-col">
        <div className="flex h-[1080px] flex-col items-end bg-[url(/public/images/img_group_16.png)] bg-cover bg-no-repeat px-[38px] py-[110px] lg:h-auto lg:py-8 md:h-auto md:p-5 sm:px-5 sm:py-4">
          <Heading
            size="heading7xl"
            as="h1"
            className="mb-[202px] rotate-[-90deg] font-gotham text-[45.92px] font-black text-white-a700 lg:text-[38px] md:text-[29px] sm:text-[23px]"
          >
            PROJETO EM ANDAMENTO
          </Heading>
        </div>
        <div className="mb-[114px] flex w-[40%] flex-col items-start gap-11 self-end md:w-full md:self-auto md:px-5">
          <Img src="images/img_group_1.svg" alt="Image" className="h-[194px] w-[22%] object-contain" />
          <Text
            size="text6xl"
            as="p"
            className="font-gotham text-[44.67px] font-normal leading-[67px] text-white-a700 lg:text-[37px] md:text-[28px] sm:text-[22px]"
          >
            <span>
              <>
                Projeto: 7On - Identidade/Site
                <br />
                Cliente:&nbsp;
              </>
            </span>
            <span className="font-black">Sexy/7On</span>
          </Text>
        </div>
      </div>
    </>
  );
}
