import { Img, Heading } from "../../components";
import UserProfile2 from "../../components/UserProfile2";
import React, { Suspense } from "react";

const data = [
  {
    profileImage: "images/img_image_496x616.png",
    userName: "Morena Mineira",
    followerCount: "76.411",
    thumbnailImage: "images/img_image_60x60.png",
  },
  {
    profileImage: "images/img_image_17.png",
    userName: "Morena Mineira",
    followerCount: "76.411",
    thumbnailImage: "images/img_image_6.png",
  },
  {
    profileImage: "images/img_image_14.png",
    userName: "Fernanda Campos",
    followerCount: "62.781",
    thumbnailImage: "images/img_image_9.png",
  },
  {
    profileImage: "images/img_image_15.png",
    userName: "Andressa",
    followerCount: "64.060",
    thumbnailImage: "images/img_image_12.png",
  },
  {
    profileImage: "images/img_image_18.png",
    userName: "Morena Mineira",
    followerCount: "76.411",
    thumbnailImage: "images/img_image_1.png",
  },
  {
    profileImage: "images/img_image_19.png",
    userName: "Morena Mineira",
    followerCount: "76.411",
    thumbnailImage: "images/img_image_2.png",
  },
  {
    profileImage: "images/img_image_20.png",
    userName: "Morena Mineira",
    followerCount: "76.411",
    thumbnailImage: "images/img_image_3.png",
  },
  {
    profileImage: "images/img_image_16.png",
    userName: "Morena Mineira",
    followerCount: "76.411",
    thumbnailImage: "images/img_image_4.png",
  },
  {
    profileImage: "images/img_image_18.png",
    userName: "Morena Mineira",
    followerCount: "76.411",
    thumbnailImage: "images/img_image_1.png",
  },
  {
    profileImage: "images/img_image_19.png",
    userName: "Morena Mineira",
    followerCount: "76.411",
    thumbnailImage: "images/img_image_2.png",
  },
  {
    profileImage: "images/img_image_20.png",
    userName: "Morena Mineira",
    followerCount: "76.411",
    thumbnailImage: "images/img_image_3.png",
  },
  {
    profileImage: "images/img_image_16.png",
    userName: "Morena Mineira",
    followerCount: "76.411",
    thumbnailImage: "images/img_image_4.png",
  },
];

export default function TestestwoColumnmorena() {
  return (
    <div className="mt-16 flex flex-col items-center gap-[122px] lg:gap-[91px] md:gap-[91px] sm:gap-[61px]">
      <div className="grid w-[64%] grid-cols-4 justify-center gap-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
        <Suspense fallback={<div>Loading feed...</div>}>
          {data.map((d, index) => (
            <UserProfile2 {...d} key={"gridmorenaminei" + index} />
          ))}
        </Suspense>
      </div>
      <div className="container-xs flex flex-col items-center px-14 lg:px-5 md:px-5">
        <div className="flex w-[20%] flex-wrap items-center justify-center lg:w-full md:w-full">
          <Heading
            size="headings"
            as="h3"
            className="flex h-[32px] w-[32px] items-center justify-center rounded-[16px] bg-gradient text-center text-[14px] font-bold tracking-[1.68px] text-blue_gray-900_01"
          >
            1
          </Heading>
          <Heading
            size="headings"
            as="h4"
            className="ml-4 text-[14px] font-bold tracking-[1.68px] text-blue_gray-900_01"
          >
            2
          </Heading>
          <Heading
            size="headings"
            as="h5"
            className="ml-4 text-[14px] font-bold tracking-[1.68px] text-blue_gray-900_01"
          >
            3
          </Heading>
          <Heading
            size="headings"
            as="h6"
            className="ml-4 text-[14px] font-bold tracking-[1.68px] text-blue_gray-900_01"
          >
            4
          </Heading>
          <Heading
            size="headings"
            as="p"
            className="ml-4 text-[14px] font-bold tracking-[1.68px] text-blue_gray-900_01"
          >
            ...
          </Heading>
          <Heading
            size="headings"
            as="p"
            className="ml-4 text-[14px] font-bold tracking-[-0.70px] text-blue_gray-900_01"
          >
            10
          </Heading>
          <Img src="images/img_arrow_right.svg" alt="Arrowright" className="ml-[22px] h-[10px]" />
        </div>
      </div>
    </div>
  );
}
