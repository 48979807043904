import { Helmet } from "react-helmet";
import FrametwoColumnThree from "./FrametwoColumnThree";
import React from "react";

export default function FrameTwoPage() {
  return (
    <>
      <Helmet>
        <title>HotPlace-Links</title>
        <meta name="description" content="Cadastro-Links" />
      </Helmet>
      <div className="w-full bg-white-a700 py-[52px] md:py-5 sm:py-4">
        <FrametwoColumnThree />
      </div>
    </>
  );
}
