import { Helmet } from "react-helmet";
import FramefiveRowOne from "./FramefiveRowOne";
import React from "react";

export default function FrameFivePage() {
  return (
    <>
      <Helmet>
        <title>HotPlace-Login</title>
        <meta name="description" content="HotPlace-Login." />
      </Helmet>
      <div className="w-full bg-white-a700 py-[182px] lg:py-8 md:py-5 sm:py-4">
        <FramefiveRowOne />
      </div>
    </>
  );
}
