import React from "react";

const sizes = {
  textxs: "text-[9px] font-medium",
  text5xl: "text-[22px] font-medium lg:text-[18px]",
  headingxs: "text-[11px] font-bold",
  headings: "text-[14px] font-bold",
  headingmd: "text-[16px] font-semibold lg:text-[13px]",
  headinglg: "text-[17px] font-bold lg:text-[14px]",
  headingxl: "text-[20px] font-bold lg:text-[17px]",
  heading2xl: "text-[26px] font-bold lg:text-[22px] md:text-[24px] sm:text-[22px]",
  heading3xl: "text-[31px] font-bold lg:text-[26px] md:text-[29px] sm:text-[27px]",
  heading4xl: "text-[32px] font-bold lg:text-[27px] md:text-[30px] sm:text-[28px]",
  heading5xl: "text-[38px] font-bold lg:text-[32px] md:text-[36px] sm:text-[34px]",
  heading6xl: "text-[44px] font-bold lg:text-[37px] md:text-[40px] sm:text-[34px]",
  heading7xl: "text-[45px] font-black lg:text-[38px] md:text-[41px] sm:text-[35px]",
};

const Heading = ({ children, className = "", size = "headinglg", as, ...restProps }) => {
  const Component = as || "h6";

  return (
    <Component className={`text-blue_gray-900_33_01 font-inter ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Heading };
