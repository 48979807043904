import { Img, Text, Heading } from "./..";
import React from "react";

export default function UserProfile1({
  profileImage = "images/img_image_14.png",
  userName = "Fernanda Campos",
  userScore = "62.781",
  backgroundImage = "images/img_image_9.png",
  ...props
}) {
  return (
    <div {...props} className={`${props.className} h-[264px] w-full relative`}>
      <div className="mx-auto flex-1">
        <Img
          src={profileImage}
          alt="Image"
          className="h-[200px] w-full rounded-tl-[30px] rounded-tr-[30px] object-cover"
        />
        <div className="rounded-bl-[28px] rounded-br-[28px] bg-blue_gray-50 p-2.5 shadow-xs">
          <div className="mb-1.5 flex items-center justify-end gap-[7px]">
            <div className="flex w-[58%] flex-col items-center">
              <Heading as="h6" className="text-[17px] font-bold tracking-[0.17px] text-blue_gray-900_01">
                {userName}
              </Heading>
              <div className="flex items-center self-stretch">
                <Img src="images/img_fi_535234_blue_500.svg" alt="Image" className="h-[12px] w-[12px]" />
                <Text size="texts" as="p" className="text-[11px] font-normal tracking-[0.11px] text-blue_gray-900_01">
                  {userScore}
                </Text>
              </div>
            </div>
            <Img src="images/img_vector.png" alt="Fernanda Campos" className="h-[22px] self-end object-cover" />
          </div>
        </div>
      </div>
      <Img
        src={backgroundImage}
        alt="Fernanda Campos"
        className="absolute bottom-[13px] left-[25px] m-auto h-[60px] w-[60px] rounded-[30px] object-cover"
      />
    </div>
  );
}
