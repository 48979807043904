import { Img, Text, Heading, Button } from "../../components";
import UserProfile1 from "../../components/UserProfile1";
import React, { Suspense } from "react";

const data = [
  {
    profileImage: "images/img_image_14.png",
    userName: "Fernanda Campos",
    userScore: "62.781",
    backgroundImage: "images/img_image_9.png",
  },
  {
    profileImage: "images/img_image_15.png",
    userName: "Fernanda Campos",
    userScore: "62.781",
    backgroundImage: "images/img_image_12.png",
  },
  {
    profileImage: "images/img_image_10.png",
    userName: "Fernanda Campos",
    userScore: "62.781",
    backgroundImage: "images/img_image_3.png",
  },
  {
    profileImage: "images/img_image_16.png",
    userName: "Xehli G",
    userScore: "41.538",
    backgroundImage: "images/img_image_4.png",
  },
];

export default function TestestwoRowTwo() {
  return (
    <div className="px-5">
      <div className="flex items-center gap-4 md:flex-col">
        <div className="w-full">
          <div className="flex flex-col items-start">
            <Button
              color="blue_gray_900"
              size="sm"
              variant="fill"
              shape="round"
              className="relative z-[1] ml-[26px] min-w-[246px] rounded-lg px-[34px] font-bold tracking-[0.17px] md:ml-0 sm:px-4"
            >
              #TOP3 MAIS VISTOS
            </Button>
            <Img
              src="images/img_image_496x616.png"
              alt="Image"
              className="relative mt-[-24px] h-[496px] w-full rounded-tl-[30px] rounded-tr-[30px] object-cover lg:h-auto md:h-auto"
            />
          </div>
          <div className="relative mt-[-8px] flex flex-col items-start">
            <Img
              src="images/img_image_60x60.png"
              alt="Image"
              className="relative z-[2] ml-[74px] h-[60px] w-[60px] rounded-[30px] object-cover md:ml-0"
            />
            <div className="relative mt-[-50px] flex justify-center self-stretch rounded-bl-[28px] rounded-br-[28px] bg-blue_gray-50 p-2.5 shadow-xs">
              <div className="mb-1 flex w-[86%] items-center justify-end lg:w-full md:w-full sm:flex-col">
                <div className="flex w-[74%] flex-col items-start sm:w-full">
                  <Heading
                    as="h4"
                    className="text-[17px] font-bold tracking-[0.17px] text-blue_gray-900_01 lg:text-[14px]"
                  >
                    Morena Mineira
                  </Heading>
                  <div className="flex items-center self-stretch">
                    <Img src="images/img_fi_535234_blue_500.svg" alt="Fi535234" className="h-[12px] w-[12px]" />
                    <Text
                      size="texts"
                      as="p"
                      className="text-[11px] font-normal tracking-[0.11px] text-blue_gray-900_01"
                    >
                      76.411
                    </Text>
                  </div>
                </div>
                <Img
                  src="images/img_favorite.svg"
                  alt="Favorite"
                  className="h-[32px] self-end sm:w-full sm:self-auto"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="ml-4 grid w-full grid-cols-2 gap-4 gap-y-8 lg:grid-cols-2 md:ml-0 md:grid-cols-1">
          <Suspense fallback={<div>Loading feed...</div>}>
            {data.map((d, index) => (
              <UserProfile1 {...d} key={"gridfernandacam" + index} />
            ))}
          </Suspense>
        </div>
      </div>
    </div>
  );
}
