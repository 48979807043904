import { Helmet } from "react-helmet";
import { Img, Heading, Slider, Input, Text } from "../../components";
import { CloseSVG } from "../../components/Input/close.jsx";
import TestesoneRowTwo from "./TestesoneRowTwo";
import React from "react";

export default function TESTESOnePage() {
  const [sliderState, setSliderState] = React.useState(0);
  const sliderRef = React.useRef(null);
  const [searchBarValue1, setSearchBarValue1] = React.useState("");

  return (
    <>
      <Helmet>
        <title>Cainan Lucas Arajo&#39;s Application9</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full flex-col gap-[50px] bg-white-a700">
        <header className="flex items-center justify-center bg-pink-900 py-2">
          <div className="container-xs flex items-center justify-between gap-5 lg:px-5 md:flex-col md:px-5">
            <Img src="images/img_header_logo_50x70.png" alt="Headerlogo" className="h-[50px] w-[70px] object-contain" />
            <div className="mb-1 flex w-[52%] items-center justify-between gap-5 self-end md:w-full md:self-auto sm:flex-col">
              <ul className="flex flex-wrap gap-[34px]">
                <li>
                  <a href="#">
                    <Text size="textmd" as="p" className="text-[14px] font-normal tracking-[1.68px] text-white-a700">
                      MULHERES
                    </Text>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <Text size="textmd" as="p" className="text-[14px] font-normal tracking-[1.68px] text-white-a700">
                      CASAIS
                    </Text>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <Text size="textmd" as="p" className="text-[14px] font-normal tracking-[1.68px] text-white-a700">
                      TRANS
                    </Text>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <Text size="textmd" as="p" className="text-[14px] font-normal tracking-[1.68px] text-white-a700">
                      HOMENS
                    </Text>
                  </a>
                </li>
              </ul>
              <Input
                color="white_A700"
                size="xs"
                variant="fill"
                name="search"
                placeholder={`Buscar modelos`}
                value={searchBarValue1}
                onChange={(e) => setSearchBarValue1(e.target.value)}
                suffix={
                  <div className="flex h-[30px] w-[32px] items-center justify-center rounded-[50%] bg-gray-300">
                    {searchBarValue1?.length > 0 ? (
                      <CloseSVG onClick={() => setSearchBarValue1("")} height={16} width={16} />
                    ) : (
                      <Img
                        src="images/img_search_black_900_01.svg"
                        alt="Search"
                        className="h-[16px] w-[16px] rounded-[50%] py-1.5 pl-2 pr-1.5"
                      />
                    )}
                  </div>
                }
                className="w-[32%] gap-4 rounded-[16px] tracking-[0.14px] text-black-900 sm:w-full"
              />
            </div>
          </div>
        </header>
        <div className="mb-1 flex flex-col items-center">
          <div className="container-xs lg:px-5 md:px-5">
            <div className="relative h-[374px] content-center lg:h-auto md:h-auto">
              <div className="sliderdestaques mx-auto flex w-full">
                <Slider
                  autoPlay
                  autoPlayInterval={2000}
                  responsive={{ 0: { items: 1 }, 551: { items: 1 }, 1051: { items: 1 }, 1441: { items: 1 } }}
                  renderDotsItem={(props) => {
                    return props?.isActive ? (
                      <div className="mr-1.5 inline-block h-[8px] w-[8px] cursor-pointer rounded-[50%] bg-pink-900" />
                    ) : (
                      <div className="mr-1.5 inline-block h-[8px] w-[8px] cursor-pointer rounded-[50%] bg-white-a700" />
                    );
                  }}
                  activeIndex={sliderState}
                  onSlideChanged={(e) => {
                    setSliderState(e?.item);
                  }}
                  ref={sliderRef}
                  items={[...Array(3)].map(() => (
                    <React.Fragment key={Math.random()}>
                      <div className="flex h-[374px] items-end bg-[url(/public/images/img_group_649.png)] bg-cover bg-no-repeat px-14 py-[62px] lg:h-auto lg:py-8 md:h-auto md:p-5 sm:p-4">
                        <div className="mt-[150px] flex w-full flex-col items-start">
                          <Heading
                            size="headingxs"
                            as="h1"
                            className="rounded-[10px] bg-pink-900 py-0.5 pl-3 pr-5 text-[11.5px] font-bold tracking-[0.12px] text-white-a700"
                          >
                            DESTAQUES DA SEMANA
                          </Heading>
                          <Heading
                            size="heading3xl"
                            as="h2"
                            className="ml-2.5 text-[31.78px] font-bold tracking-[0.32px] text-white-a700 lg:text-[26px] md:ml-0 md:text-[25px] sm:text-[23px]"
                          >
                            Debora Peixoto
                          </Heading>
                          <div className="mx-3 flex items-start self-stretch md:mx-0">
                            <Img src="images/img_fi_535234.svg" alt="Fi535234" className="h-[22px] w-[22px]" />
                            <Heading
                              size="headingxl"
                              as="h3"
                              className="self-center text-[20.92px] font-bold tracking-[0.21px] text-white-a700 lg:text-[17px]"
                            >
                              45.115
                            </Heading>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                />
              </div>
            </div>
          </div>
          <TestesoneRowTwo />
          <div className="container-xs mt-16 px-3.5 lg:px-5 md:px-5">
            <Img
              src="images/img_image_6_482x1170.png"
              alt="Imagesix"
              className="mr-1 h-[482px] w-full object-cover lg:h-auto md:mr-0 md:h-auto"
            />
          </div>
          <Img
            src="images/img_image_5_268x1242.png"
            alt="Imagefive"
            className="mt-[332px] h-[268px] w-[64%] object-contain"
          />
        </div>
      </div>
    </>
  );
}
