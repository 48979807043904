import { Button, Img, Text, Heading, SelectBox, Input } from "../../components";
import UserProfile3 from "../../components/UserProfile3";
import React, { useState } from "react";

const dropDownOptions = [
  { label: "Mulher", value: "mulher" },
  { label: "Homem", value: "homem" },
  { label: "Trans", value: "trans" },
  { label: "Casais", value: "casais" },
];

export default function FramethreeColumnTwo() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [carouselFiles, setCarouselFiles] = useState([]);

  // Function to handle single profile photo file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  // Function to handle multiple carousel photos selection
  const handleCarouselFilesChange = (event) => {
    const files = Array.from(event.target.files).slice(0, 3); // Limiting to 3 files
    setCarouselFiles(files);
  };

  // Function to handle removal of the selected profile photo
  const removeProfilePhoto = () => {
    setSelectedFile(null);
  };

  // Function to handle removal of the selected carousel photos
  const removeCarouselPhotos = () => {
    setCarouselFiles([]);
  };

  return (
    <div className="mb-1 flex flex-col items-center">
      <div className="container-xs flex flex-col items-center gap-12 lg:px-5 md:px-5">
        <Heading
          size="text5xl"
          as="h2"
          className="text-center text-[22.68px] font-medium leading-[27px] tracking-[0.23px] text-black lg:text-[18px]"
        >
          <span>Após a conclusão,&nbsp;</span>
          <span className="font-light">
            <>
              nosso suporte analisará e entrará em
              <br />
              contato sobre a aprovação, ativando seu perfil no catálogo.
            </>
          </span>
        </Heading>
        <div className="flex flex-col items-center gap-[100px] self-stretch lg:gap-[100px] md:gap-[75px] sm:gap-[50px]">
          <div className="flex flex-col items-center gap-[100px] self-stretch rounded-[30px] bg-gray-50 p-14 shadow-xs lg:gap-[100px] md:gap-[75px] md:p-5 sm:gap-[50px] sm:p-4">
            <div className="mr-[46px] flex w-[94%] flex-col gap-[50px] lg:w-full md:mr-0 md:w-full">
              <div className="flex items-center gap-[21px]">
                <Img src="images/img_frame_11.png" alt="Image" className="h-[36px] rounded object-cover" />
                <Heading
                  size="heading4xl"
                  as="h3"
                  className="text-[32px] font-bold tracking-[0.32px] text-black lg:text-[27px] md:text-[26px] sm:text-[24px]"
                >
                  Fotos
                </Heading>
              </div>
              <div className="flex gap-[100px] md:flex-col items-center">
                
                {/* Single Profile Photo Section */}
                <div className="flex items-center gap-4 relative">
                  <UserProfile3
                    profilePictureText="Foto de Perfil"
                    choosePhotoButtonText={
                      <label
                        htmlFor="singleFileUpload"
                        className="cursor-pointer text-white"
                      >
                        Escolher foto
                      </label>
                    }
                    noFileChosenText={selectedFile ? selectedFile.name : "Nenhum arquivo escolhido"}
                  />
                  <input
                    id="singleFileUpload"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  {selectedFile && (
                    <div className="flex flex-col items-start">
                      {/* Display uploaded file name */}
                      <Text>{selectedFile.name}</Text>
                      <button onClick={removeProfilePhoto} className="absolute left-[-40px] trash-icon">
                        <span
                          style={{
                            color: "red",
                            fontSize: "24px",
                            cursor: "pointer",
                          }}
                        >
                          X
                        </span>
                      </button>
                    </div>
                  )}
                </div>

                {/* Carousel Photos Section */}
                <div className="flex items-center gap-4 relative">
                  <UserProfile3
                    profilePictureText="Foto do Carrossel (3 fotos)"
                    choosePhotoButtonText={
                      <label
                        htmlFor="carouselFileUpload"
                        className="cursor-pointer text-white"
                      >
                        Escolher fotos
                      </label>
                    }
                    noFileChosenText={
                      carouselFiles.length > 0
                        ? `${carouselFiles.length} arquivos escolhidos`
                        : "Nenhum arquivo escolhido"
                    }
                  />
                  <input
                    id="carouselFileUpload"
                    type="file"
                    multiple
                    style={{ display: "none" }}
                    onChange={handleCarouselFilesChange}
                  />
                  {carouselFiles.length > 0 && (
                    <div className="flex flex-col items-start">
                      {/* Display list of uploaded files */}
                      <ul>
                        {carouselFiles.map((file, index) => (
                          <li key={index}>{file.name}</li>
                        ))}
                      </ul>
                      <button onClick={removeCarouselPhotos} className="absolute left-[-40px] trash-icon">
                        <span
                          style={{
                            color: "red",
                            fontSize: "24px",
                            cursor: "pointer",
                          }}
                        >
                          X
                        </span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Rest of the form fields */}
            <div className="flex w-[94%] flex-col gap-[58px] lg:w-full md:w-full sm:gap-[29px]">
              <div className="flex items-center gap-[21px]">
                <Img src="images/img_frame_11.png" alt="Image" className="h-[36px] rounded object-cover" />
                <Heading
                  size="heading4xl"
                  as="h4"
                  className="text-[32px] font-bold tracking-[0.32px] text-black lg:text-[27px] md:text-[26px] sm:text-[24px]"
                >
                  Nome do Perfil
                </Heading>
              </div>
              <div className="flex md:flex-col">
                <div className="flex flex-1 flex-col items-start gap-5 md:self-stretch">
                  <Text
                    as="p"
                    className="text-[18px] font-normal tracking-[0.18px] text-black lg:text-[15px]"
                  >
                    Nome do Perfil
                  </Text>
                  <Input
                    shape="round"
                    name="seu_nome"
                    placeholder={`Seu Nome `}
                    className="w-[68%] rounded-lg !border px-[18px] italic tracking-[0.18px]"
                  />
                </div>
                <div className="flex w-[46%] flex-col items-start gap-5 md:w-full">
                  <Text
                    as="p"
                    className="text-[18px] font-normal tracking-[0.18px] text-black lg:text-[15px]"
                  >
                    Categoria
                  </Text>
                  <SelectBox
                    shape="round"
                    indicator={<Img src="images/img_arrowdown.svg" alt="Arrow Down" className="h-[18px] w-[24px]" />}
                    name="arrowdown"
                    options={dropDownOptions}
                    className="w-[76%] gap-4 rounded-[10px] !border px-[18px]"
                  />
                </div>
              </div>
              <div className="flex items-center gap-[34px] md:flex-col">
                <div className="flex w-[46%] justify-center rounded-[44px] bg-gradient2 p-5 shadow-2xl md:w-full">
                  <div className="mt-[46px] flex w-full flex-col items-center gap-[52px] sm:gap-[26px]">
                    <Img src="images/img_group_1000003621.svg" alt="Image" className="h-[178px] w-[178px]" />
                    <div className="flex items-center justify-center self-stretch sm:flex-col">
                      <div className="flex flex-1 items-center justify-center gap-3.5 sm:self-stretch">
                        <div className="flex flex-col items-center justify-center rounded-[46px] bg-gray-400_01 p-5">
                          <Img src="images/img_lock.svg" alt="Lock" className="h-[48px] w-[48px]" />
                        </div>
                        <div className="mb-1.5 flex flex-1 flex-col items-start self-end">
                          <Heading
                            size="heading2xl"
                            as="h5"
                            className="text-[26.66px] font-bold tracking-[0.27px] text-black lg:text-[22px]"
                          >
                            Seu Nome
                          </Heading>
                          <div className="flex self-stretch">
                            <Img
                              src="images/img_fi_535234_20x20.png"
                              alt="Fi535234"
                              className="h-[20px] w-[20px] object-cover"
                            />
                            <Text
                              size="text2xl"
                              as="p"
                              className="ml-1 text-[17.25px] font-normal tracking-[0.17px] text-black lg:text-[14px]"
                            >
                              62.781
                            </Text>
                          </div>
                        </div>
                      </div>
                      {/* Hides this image on mobile screens */}
                      <Img
                        src="images/img_fi_535234_20x20.png"
                        alt="Fi535234"
                        className="mb-2.5 h-[38px] w-[38px] self-end object-cover sm:hidden"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-1 gap-[34px] md:self-stretch sm:flex-col">
                  <div className="flex w-full flex-col items-center justify-center rounded-[26px] bg-blue_gray-100 px-14 py-[72px] lg:py-8 md:p-5 sm:w-full sm:p-4">
                    <Img src="images/img_lock_black_900_01.svg" alt="Lock" className="h-[94px] w-[94px]" />
                  </div>
                  <div className="flex w-full flex-col items-center justify-center rounded-[26px] bg-blue_gray-100 px-14 py-[72px] lg:py-8 md:p-5 sm:w-full sm:p-4">
                    <Img src="images/img_lock_black_900_01.svg" alt="Lock" className="h-[94px] w-[94px]" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Previous, Next, and Seja Premium Buttons */}
          <div className="flex gap-[30px] sm:justify-center sm:flex-col sm:items-center">
            <Button
              variant="fill"
              shape="round"
              className="min-w-[170px] rounded-[10px] px-[34px] font-bold tracking-[0.16px] sm:px-4"
            >
              Anterior
            </Button>
            <Button
              shape="round"
              color="purple_A200_cyan_A100"
              className="min-w-[170px] rounded-[10px] px-[34px] font-bold tracking-[0.16px] sm:px-4 sm:mt-4"
            >
              Próximo
            </Button>
            {/* Seja Premium Button - Only on Mobile */}
            <div className="sm:block lg:hidden sm:mt-4">
              <Button
                shape="round"
                color="purple_A200_cyan_A100"
                className="min-w-[170px] rounded-[10px] px-[34px] font-bold tracking-[0.16px]"
              >
                Seja Premium
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
