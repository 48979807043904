import { Helmet } from "react-helmet";
import { Text, Button, CheckBox, Input, Heading, Img } from "../../components";
import React from "react";

export default function DesktopPage() {
  return (
    <>
      <Helmet>
        <title>Cainan Lucas Arajo&#39;s Application9</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full rotate-[-180deg] items-center bg-white-a700 px-14 py-[120px] md:flex-col md:p-5">
        <div className="relative mb-2.5 h-[698px] w-[46%] self-end px-[70px] md:w-full md:self-auto md:px-5">
          <Img
            src="images/img_0_1_2.png"
            alt="Image"
            className="absolute bottom-0 left-[13%] top-0 my-auto h-[690px] w-[86%] object-contain"
          />
          <Img
            src="images/img_0_1_1.png"
            alt="Image"
            className="absolute bottom-0 left-[12%] top-0 my-auto h-[690px] w-[84%] object-contain"
          />
          <div className="absolute right-[21%] top-[15px] m-auto flex h-[40px] w-[34%] items-center justify-center bg-[url(/public/images/img_group_8.png)] bg-cover bg-no-repeat py-1.5 md:h-auto">
            <div className="flex w-full flex-wrap justify-center">
              <Text
                size="text4xl"
                as="p"
                className="text-shadow-ts rotate-[-180deg] font-outfit text-[19.92px] font-light tracking-[0.20px] text-black-900_01"
              >
                HOT
              </Text>
              <Text
                size="text4xl"
                as="p"
                className="text-shadow-ts rotate-[-180deg] font-outfit text-[19.92px] font-light tracking-[0.20px] text-white-a700"
              >
                PLACE
              </Text>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col items-end md:self-stretch">
          <div className="flex w-[82%] flex-col items-center justify-center rounded-[56px] bg-gray-900_02 px-[42px] py-[60px] shadow-xl md:w-full md:p-5">
            <Heading
              size="heading4xl"
              as="h1"
              className="rotate-[-180deg] text-[32px] font-bold tracking-[0.32px] text-white-a700 md:text-[30px] sm:text-[28px]"
            >
              <span className="text-white-a700">
                <>
                  Aumente sua audiência
                  <br />
                  e&nbsp;
                </>
              </span>
              <span className="bg-gradient bg-clip-text text-transparent">fature mais!</span>
            </Heading>
            <Heading
              size="headingmd"
              as="h2"
              className="ml-1 mt-[54px] rotate-[-180deg] self-start text-[16px] font-normal tracking-[0.16px] text-white-a700 md:ml-0"
            >
              <span>Acesse sua&nbsp;</span>
              <span>conta</span>
              <span>:</span>
            </Heading>
            <div className="mb-1.5 mt-8 flex flex-col items-center self-stretch">
              <div className="flex flex-col items-start gap-4 self-stretch">
                <Text
                  as="p"
                  className="ml-1 rotate-[-180deg] text-[18px] font-normal tracking-[0.18px] text-white-a700 md:ml-0"
                >
                  Email
                </Text>
                <Input
                  color="gray_400_01"
                  size="sm"
                  shape="round"
                  name="email"
                  className="rotate-[-180deg] self-stretch rounded-lg !border-[0.94px] px-[18px]"
                />
              </div>
              <Text
                as="p"
                className="ml-1 mt-4 rotate-[-180deg] self-start text-[18px] font-normal tracking-[0.18px] text-white-a700 md:ml-0"
              >
                Senha
              </Text>
              <Input
                color="gray_400_01"
                shape="round"
                type="password"
                name="password"
                placeholder={`********`}
                className="mt-4 self-stretch rounded-lg !border-[0.94px] px-[18px] tracking-[0.19px]"
              />
              <div className="ml-1.5 mr-4 mt-4 flex justify-between gap-5 self-stretch md:mx-0 sm:flex-col">
                <CheckBox
                  name="mantenhameconec"
                  label="Mantenha-me conectado"
                  id="mantenhameconec"
                  className="gap-2 text-[18px] font-semibold tracking-[0.18px] text-white-a700"
                />
                <Text
                  as="p"
                  className="rotate-[-180deg] bg-gradient bg-clip-text text-[18px] font-normal tracking-[0.18px] text-transparent"
                >
                  Precisa de ajuda?
                </Text>
              </div>
              <Button
                size="2xl"
                shape="round"
                color="purple_A200_cyan_A100"
                className="ml-1.5 mr-1 mt-[34px] self-stretch rounded-lg px-[34px] font-bold tracking-[0.19px] md:mx-0 sm:px-5"
              >
                Entrar
              </Button>
              <Text as="p" className="mt-9 rotate-[-180deg] text-[18px] font-normal tracking-[0.18px] text-gray-700">
                <span className="font-thin text-white-a700">Não tem uma conta?</span>
                <span className="text-gray-700">
                  <>
                    &nbsp;
                    <br />
                  </>
                </span>
                <span className="bg-gradient bg-clip-text font-semibold text-transparent">Cadastre-se</span>
                <span className="text-gray-700">
                  <>
                    <br />
                    <br />
                  </>
                </span>
                <span className="text-[20px] font-black text-white-a700">Voltar</span>
              </Text>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
