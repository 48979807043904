import { Heading } from "../../components";
import React from "react";

export default function TestestwoRowFive() {
  return (
    <div className="flex justify-center px-14 md:px-5 sm:px-4">
      <div className="flex w-[68%] justify-center bg-blue_gray-100 py-[58px] shadow-xs lg:w-full md:w-full md:py-5 sm:py-4">
        <div className="container-xs flex justify-center px-14 lg:px-5 md:px-5">
          <Heading
            size="heading5xl"
            as="h2"
            className="text-[38.08px] font-bold tracking-[0.38px] text-gray-400 lg:text-[32px] md:text-[32px] sm:text-[30px]"
          >
            BANNER ADS
          </Heading>
        </div>
      </div>
    </div>
  );
}
