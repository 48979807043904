import React from "react";
import PropTypes from "prop-types";

const shapes = {
  square: "rounded-[0px]",
  round: "rounded-lg",
};
const variants = {
  fill: {
    blue_gray_900: "bg-blue_gray-900 text-white-a700",
    blue_gray_100: "bg-blue_gray-100 text-gray-900_01",
    blue_gray_900_01: "bg-blue_gray-900_01 text-white-a700",
    white_A700: "bg-white-a700 shadow-xs text-blue_gray-900_01",
  },
  gradient: {
    purple_A200_cyan_A100: "bg-gradient shadow-xs text-blue_gray-900_01",
  },
};
const sizes = {
  xs: "h-[30px] px-5 text-[9px]",
  sm: "h-[42px] px-[34px] text-[17px]",
  lg: "h-[54px] px-[30px] text-[21px]",
  "2xl": "h-[66px] px-[34px] text-[18px]",
  xl: "h-[66px]",
  md: "h-[50px] px-[30px] text-[16px]",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant = "gradient",
  size = "md",
  color = "white_A700",
  ...restProps
}) => {
  return (
    <button
      className={`${className} flex flex-row items-center justify-center text-center cursor-pointer whitespace-nowrap ${shape && shapes[shape]} ${size && sizes[size]} ${variant && variants[variant]?.[color]}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  shape: PropTypes.oneOf(["square", "round"]),
  size: PropTypes.oneOf(["xs", "sm", "lg", "2xl", "xl", "md"]),
  variant: PropTypes.oneOf(["fill", "gradient"]),
  color: PropTypes.oneOf(["blue_gray_900", "blue_gray_100", "blue_gray_900_01", "white_A700", "purple_A200_cyan_A100"]),
};

export { Button };
