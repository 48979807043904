import { Helmet } from "react-helmet";
import { Button, Heading, Text } from "../../components";
import FrameoneoneColumnTwo from "./FrameoneoneColumnTwo";
import React from "react";

export default function FrameOneOnePage() {
  return (
    <>
      <Helmet>
        <title>Cainan Lucas Arajo&#39;s Application9</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="w-full bg-white-a700 py-[52px] md:py-5 sm:py-4">
        <div className="mb-[98px] flex flex-col gap-9">
          <div className="mx-20 flex items-start justify-center md:mx-0 md:flex-col">
            <div className="flex flex-1 items-center md:self-stretch md:px-5">
              <div className="flex h-[30px] w-[6%] items-center justify-center bg-[url(/public/images/img_group_14.png)] bg-cover bg-no-repeat py-1 lg:h-auto md:h-auto">
                <div className="flex w-full flex-wrap justify-center">
                  <Text
                    size="textlg"
                    as="p"
                    className="text-shadow-ts1 font-outfit text-[15.13px] font-light tracking-[0.15px] text-black-900_01"
                  >
                    HOT
                  </Text>
                  <Text
                    size="textlg"
                    as="p"
                    className="text-shadow-ts1 font-outfit text-[15.13px] font-light tracking-[0.15px] text-white-a700"
                  >
                    PLACE
                  </Text>
                </div>
              </div>
              <div className="flex flex-1 flex-wrap justify-center gap-5 px-14 md:px-5 sm:px-4">
                <Heading
                  size="text5xl"
                  as="h1"
                  className="text-shadow-ts2 flex h-[42px] w-[42px] items-center justify-center rounded-[20px] bg-blue_gray-100 text-center text-[22.68px] font-medium tracking-[0.23px] text-black-900_01 lg:text-[18px]"
                >
                  1
                </Heading>
                <Heading
                  size="text5xl"
                  as="h2"
                  className="text-shadow-ts2 flex h-[42px] w-[42px] items-center justify-center rounded-[20px] bg-blue_gray-100 text-center text-[22.68px] font-medium tracking-[0.23px] text-black-900 lg:text-[18px]"
                >
                  2
                </Heading>
                <Heading
                  size="text5xl"
                  as="h3"
                  className="text-shadow-ts2 flex h-[42px] w-[42px] items-center justify-center rounded-[20px] bg-gradient text-center text-[22.68px] font-medium tracking-[0.23px] text-black-900 lg:text-[18px]"
                >
                  3
                </Heading>
              </div>
            </div>
            <Button
              shape="round"
              color="purple_A200_cyan_A100"
              className="mt-1.5 min-w-[170px] self-end rounded-[10px] px-[30px] font-bold tracking-[0.16px] md:self-auto md:px-5"
            >
              Seja Premium
            </Button>
          </div>
          <FrameoneoneColumnTwo />
        </div>
      </div>
    </>
  );
}
