import React from "react";
import PropTypes from "prop-types";

const shapes = {
  round: "rounded-lg",
};

const variants = {
  fill: {
    gray_300_33: "bg-gray-300_33",
    white_A700: "bg-white-a700",
    gray_300_02: "bg-gray-300_02",
  },
  outline: {
    gray_400_01: "border-gray-400_01 border-[0.94px] border-solid text-white-a700",
    gray_600: "border-gray-600 border border-solid text-gray-400_01",
  },
};

const sizes = {
  xs: "h-[34px] pl-5 pr-3 text-[14px]",
  sm: "h-[48px] px-[18px]",
  md: "h-[50px] px-[18px] text-[18px]",
};

const Input = React.forwardRef(
  (
    {
      className = "",
      name = "",
      placeholder = "",
      type = "text",
      label = "",
      prefix,
      suffix,
      onChange,
      shape,
      variant = "outline",
      size = "md",
      color = "gray_600",
      ...restProps
    },
    ref,
  ) => {
    return (
      <label
        className={`${className} flex items-center justify-center cursor-text  ${shape && shapes[shape]} ${variant && (variants[variant]?.[color] || variants[variant])} ${size && sizes[size]}`}
      >
        {!!label && label}
        {!!prefix && prefix}
        <input ref={ref} type={type} name={name} placeholder={placeholder} onChange={onChange} {...restProps} />
        {!!suffix && suffix}
      </label>
    );
  },
);
Input.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  shape: PropTypes.oneOf(["round"]),
  size: PropTypes.oneOf(["xs", "sm", "md"]),
  variant: PropTypes.oneOf(["fill", "outline"]),
  color: PropTypes.oneOf(["gray_300_33", "white_A700", "gray_300_02", "gray_400_01", "gray_600"]),
};

export { Input };
