import { Img, Text, Heading } from "./..";
import React from "react";

export default function UserProfile2({
  profileImage = "images/img_image_496x616.png",
  userName = "Morena Mineira",
  followerCount = "76.411",
  thumbnailImage = "images/img_image_60x60.png",
  ...props
}) {
  return (
    <div {...props} className={`${props.className} h-[264px] w-full relative`}>
      <div className="mx-auto flex-1">
        <Img
          src={profileImage}
          alt="Image"
          className="h-[200px] w-full rounded-tl-[30px] rounded-tr-[30px] object-cover"
        />
        <div className="flex items-start justify-center gap-[22px] rounded-bl-[28px] rounded-br-[28px] bg-blue_gray-50 p-2.5 shadow-xs">
          <div className="mb-1.5 flex flex-1 justify-end">
            <div className="flex w-[62%] flex-col items-center">
              <Heading as="h6" className="text-[17px] font-bold tracking-[0.17px] text-blue_gray-900_01">
                {userName}
              </Heading>
              <div className="flex items-center self-stretch">
                <Img src="images/img_fi_535234_blue_500.svg" alt="Image" className="h-[12px] w-[12px]" />
                <Text size="texts" as="p" className="text-[11px] font-normal tracking-[0.11px] text-blue_gray-900_01">
                  {followerCount}
                </Text>
              </div>
            </div>
          </div>
          <Img src="images/img_favorite.svg" alt="Morena Mineira" className="mb-1.5 h-[22px] self-end" />
        </div>
      </div>
      <Img
        src={thumbnailImage}
        alt="Morena Mineira"
        className="absolute bottom-[13px] left-[27px] m-auto h-[60px] w-[60px] rounded-[30px] object-cover"
      />
    </div>
  );
}
