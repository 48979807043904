import { Img } from "../../components";
import React from "react";

export default function TestesoneRowTwo() {
  return (
    <div className="mt-16 flex justify-center self-stretch">
      <div className="container-xs flex justify-center lg:px-5 md:px-5">
        <div className="flex w-full items-center md:flex-col">
          <Img
            src="images/img_image_3_358x898.png"
            alt="Imagethree"
            className="h-[358px] w-[74%] object-contain md:w-full"
          />
          <div className="relative ml-[-8px] w-[26%] md:ml-0 md:w-full">
            <Img
              src="images/img_image_7_364x296.png"
              alt="Imageseven"
              className="mr-[18px] h-[364px] w-full object-cover lg:h-auto md:mr-0 md:h-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
