import { Img } from "../../components";
import React from "react";

export default function FrameoneRow() {
  return (
    <div className="flex px-[26px] sm:px-4">
      <Img
        className="container-sm h-[282px] object-cover lg:h-auto md:h-auto"
        src="images/img_image_9_282x1902.png"
        alt="Imagenine"
      />
    </div>
  );
}
