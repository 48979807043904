import { Helmet } from "react-helmet";
import { Button, Text, Img } from "../../components";
import FramefourColumnTwo from "./FramefourColumnTwo";
import React from "react";

export default function FrameFourPage() {
  return (
    <>
      <Helmet>
        <title>HotPlace-Cadastro</title>
        <meta name="description" content="Cadastro" />
      </Helmet>
      <div className="w-full bg-white-a700 py-[58px] md:py-5 sm:py-4">
        <div className="mb-[104px] flex flex-col gap-[54px] sm:gap-[27px]">
          <div className="mx-20 flex items-start justify-between gap-5 md:mx-0 sm:items-center sm:justify-start sm:flex-row">
            {/* Ajuste da logo no mobile */}
            <div className="flex h-[30px] w-[6%] sm:w-[30%] sm:justify-end items-center justify-center">
              <Img
                src="/images/logo.png"
                alt="Logo"
                className="object-cover w-full h-auto sm:w-[100px]" // Logo maior à direita no mobile
              />
            </div>
            {/* Botão "Seja Premium" ajustado no mobile, movido ainda mais para a direita */}
            <button className="premium-button min-w-[170px] sm:min-w-[120px] self-center rounded-[10px] px-[30px] sm:px-[15px] font-bold tracking-[0.16px] flex flex-row items-center justify-center text-center cursor-pointer whitespace-nowrap h-[50px] sm:h-[40px] text-[16px] sm:text-[12px] bg-gradient shadow-xs text-white sm:self-start sm:ml-32">
              Seja Premium
            </button>
          </div>
          <FramefourColumnTwo />
        </div>
      </div>
    </>
  );
}
