import { CloseSVG } from "../Input/close.jsx";
import { Input, Img, Text } from "./..";
import React from "react";

export default function Header({ ...props }) {
  const [searchBarValue, setSearchBarValue] = React.useState("");

  return (
    <header {...props} className={`${props.className} flex flex-col gap-7`}>
      <div className="flex justify-center self-stretch bg-gray-800_33 py-1.5">
        <div className="container-xs flex items-center justify-center gap-2 self-end px-14 lg:px-5 md:px-5">
          <a href="#">
            <Img src="images/img_fi_535234.svg" alt="Fi535234" className="h-[20px] w-[20px]" />
          </a>
          <Text size="textmd" as="p" className="text-[14px] font-normal tracking-[1.68px] text-white-a700">
            14678 Visualizadores
          </Text>
        </div>
      </div>
      <div className="mx-auto flex w-full max-w-[1230px] items-center justify-between gap-5 self-stretch lg:px-5 md:flex-col md:px-5">
        <Img src="images/img_header_logo.svg" alt="Headerlogo" className="h-[50px] w-[78px] object-contain" />
        <div className="flex w-[52%] items-center justify-between gap-5 md:w-full sm:flex-col">
          <ul className="flex flex-wrap gap-[34px]">
            <li>
              <a href="#">
                <Text size="textmd" as="p" className="text-[14px] font-normal tracking-[1.68px] text-blue_gray-900_33">
                  MULHERES
                </Text>
              </a>
            </li>
            <li>
              <a href="#">
                <Text size="textmd" as="p" className="text-[14px] font-normal tracking-[1.68px] text-blue_gray-900_33">
                  CASAIS
                </Text>
              </a>
            </li>
            <li>
              <a href="#">
                <Text size="textmd" as="p" className="text-[14px] font-normal tracking-[1.68px] text-blue_gray-900_33">
                  TRANS
                </Text>
              </a>
            </li>
            <li>
              <a href="#">
                <Text size="textmd" as="p" className="text-[14px] font-normal tracking-[1.68px] text-blue_gray-900_33">
                  HOMENS
                </Text>
              </a>
            </li>
          </ul>
          <Input
            color="gray_300_33"
            size="xs"
            variant="fill"
            name="search"
            placeholder={`Buscar modelos`}
            value={searchBarValue}
            onChange={(e) => setSearchBarValue(e.target.value)}
            suffix={
              <div className="flex h-[16px] w-[16px] items-center justify-center">
                {searchBarValue?.length > 0 ? (
                  <CloseSVG onClick={() => setSearchBarValue("")} height={16} width={16} />
                ) : (
                  <Img src="images/img_search.svg" alt="Search" className="h-[16px] w-[16px]" />
                )}
              </div>
            }
            className="w-[32%] gap-4 rounded-[16px] tracking-[0.14px] text-gray-700_33 sm:w-full"
          />
        </div>
      </div>
    </header>
  );
}
