import { Helmet } from "react-helmet";
import { Img } from "../../components";
import Sidebar1 from "../../components/Sidebar1";
import React from "react";

export default function ExperinciadoClientePage() {
  return (
    <>
      <Helmet>
        <title>Cainan Lucas Arajo&#39;s Application9</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="w-full bg-indigo-100 py-5">
        <div className="mb-1.5 px-[34px] sm:px-4">
          <div className="relative h-[1034px] rounded-[20px] bg-gradient1 px-[18px] py-[26px] sm:py-4">
            <div className="absolute bottom-0 left-0 right-0 top-0 m-auto h-[942px] flex-1 content-end lg:h-auto md:h-auto">
              <Img
                src="images/img_vector_1.svg"
                alt="Vectorone"
                className="mb-[260px] ml-[170px] h-[184px] w-[3%] object-contain lg:ml-0 md:ml-0"
              />
              <div className="absolute bottom-0 left-0 right-0 top-0 m-auto flex h-max flex-1 items-start">
                <Sidebar1 />
                <div className="flex flex-1 gap-6 rounded-[20px] bg-gray-200 p-[26px] md:flex-col sm:p-4">
                  <div className="w-[16%] rounded-[20px] bg-white-a700 p-3.5 shadow-sm md:w-full md:px-5">
                    <div className="flex flex-col items-start gap-[26px] rounded-[20px] bg-gray-50_01 px-[18px] py-6 sm:py-4">
                      <div className="flex flex-col items-center justify-center rounded-[34px] bg-deep_purple-a200_01 p-3 shadow-md">
                        <Img src="images/img_relogio.png" alt="Relogio" className="h-[42px] w-[42px] object-cover" />
                      </div>
                      <div className="h-px self-stretch bg-gray-200_01" />
                      <div className="flex flex-col items-center justify-center rounded-[34px] bg-deep_purple-a200_01 px-3 py-3.5 shadow-md">
                        <Img
                          src="images/img_formato_de_arquivo_odc.png"
                          alt="Formatode"
                          className="h-[38px] object-cover"
                        />
                      </div>
                      <div className="h-px self-stretch bg-gray-200_01" />
                      <div className="flex flex-col items-center justify-center rounded-[34px] bg-deep_purple-a200_01 p-3 shadow-md">
                        <Img src="images/img_perigo.png" alt="Perigo" className="h-[42px] w-[42px] object-cover" />
                      </div>
                      <div className="h-px self-stretch bg-gray-200_01" />
                      <div className="flex flex-col items-center justify-center rounded-[34px] bg-deep_purple-a200_01 p-3.5 shadow-md">
                        <Img
                          src="images/img_operador_de_camera.png"
                          alt="Operadorde"
                          className="h-[38px] object-cover"
                        />
                      </div>
                      <div className="h-px self-stretch bg-gray-200_01" />
                      <div className="flex flex-col items-center justify-center rounded-[34px] bg-deep_purple-a200_01 p-3.5 shadow-md">
                        <Img src="images/img_ondas_de_audio.png" alt="Ondasdeaudio" className="h-[38px] object-cover" />
                      </div>
                      <div className="h-px self-stretch bg-gray-200_01" />
                      <div className="flex flex-col items-center justify-center rounded-[34px] bg-deep_purple-a200_01 p-3 shadow-md">
                        <Img src="images/img_fraude.png" alt="Fraude" className="h-[42px] w-[42px] object-cover" />
                      </div>
                      <div className="h-px self-stretch bg-gray-200_01" />
                      <div className="mb-3 flex flex-col items-center justify-center rounded-[34px] bg-deep_purple-a200_01 p-3.5 shadow-md">
                        <Img src="images/img_chamada_telefonica.png" alt="Chamada" className="h-[38px] object-cover" />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-1 flex-col gap-6 md:self-stretch">
                    <div className="flex flex-col gap-[22px]">
                      <div className="h-[48px] rounded-[16px] bg-deep_purple-a200_01 shadow-lg" />
                      <div className="h-[364px] rounded-[16px] bg-white-a700 shadow-sm" />
                    </div>
                    <Img src="images/img_group_3.svg" alt="Image" className="h-[428px]" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
