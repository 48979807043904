import { Heading, Img, Slider } from "../../components";
import React from "react";

export default function TestestwoColumn() {
  const [sliderState, setSliderState] = React.useState(0);
  const sliderRef = React.useRef(null);

  return (
    <div className="mx-auto w-full">
      <div className="flex w-full">
        <Slider
          autoPlay
          autoPlayInterval={2000}
          responsive={{ 0: { items: 1 }, 551: { items: 1 }, 1051: { items: 1 }, 1441: { items: 1 } }}
          disableDotsControls
          activeIndex={sliderState}
          onSlideChanged={(e) => {
            setSliderState(e?.item);
          }}
          ref={sliderRef}
          items={[...Array(3)].map(() => (
            <React.Fragment key={Math.random()}>
              <div className="flex h-[580px] items-end bg-[url(/public/images/img_group_659.png)] bg-cover bg-no-repeat py-[62px] lg:h-auto lg:py-8 md:h-auto md:py-5 sm:py-4">
                <div className="container-xs mt-[356px] flex justify-center px-[18px] lg:px-5 md:px-5">
                  <div className="w-full">
                    <div className="flex flex-col items-start">
                      <Heading
                        size="headingxs"
                        as="h1"
                        className="flex items-center justify-center rounded-[5px] bg-blue_gray-900 px-3 py-0.5 text-[11.5px] font-bold tracking-[0.12px] text-white-a700"
                      >
                        DESTAQUES DA SEMANA
                      </Heading>
                      <Heading
                        size="heading3xl"
                        as="h2"
                        className="ml-2.5 text-[31.78px] font-bold tracking-[0.32px] text-white-a700 lg:text-[26px] md:ml-0 md:text-[25px] sm:text-[23px]"
                      >
                        Debora Peixoto
                      </Heading>
                      <div className="mx-3 flex items-start self-stretch md:mx-0">
                        <Img src="images/img_fi_535234.svg" alt="Fi535234" className="h-[22px] w-[22px]" />
                        <Heading
                          size="headingxl"
                          as="h3"
                          className="self-center text-[20.92px] font-bold tracking-[0.21px] text-white-a700 lg:text-[17px]"
                        >
                          45.115
                        </Heading>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
        />
      </div>
    </div>
  );
}
