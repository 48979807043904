import { Text, Img, Heading } from "./..";
import React from "react";

export default function UserProfile({
  userImage = "images/img_image_1.png",
  userName = "Andry Silva",
  userScore = "48.509",
  ...props
}) {
  return (
    <div {...props} className={`${props.className} flex flex-col items-start w-[24%] md:w-full`}>
      <Img
        src={userImage}
        alt="Andry Silva"
        className="relative z-[3] ml-11 h-[60px] w-[60px] rounded-[30px] object-cover opacity-20"
      />
      <div className="relative mt-[-50px] flex justify-center self-stretch rounded-bl-[28px] rounded-br-[28px] bg-blue_gray-50_33 p-2.5">
        <div className="mb-2 flex w-[34%] flex-col items-center">
          <Heading as="h6" className="text-[17px] font-bold tracking-[0.17px] text-blue_gray-900_33_01">
            {userName}
          </Heading>
          <div className="flex items-center self-stretch">
            <Img src="images/img_fi_535234_blue_500.svg" alt="Image" className="h-[12px] w-[12px] self-end" />
            <Text size="texts" as="p" className="text-[11px] font-normal tracking-[0.11px] text-blue_gray-900_33_01">
              {userScore}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
}
