import { Helmet } from "react-helmet";
import FrameoneRow from "./FrameoneRow";
import FrameoneRowOne from "./FrameoneRowOne";
import React from "react";

export default function FrameOnePage() {
  return (
    <>
      <Helmet>
        <title>Cainan Lucas Arajo&#39;s Application9</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full flex-col gap-[88px] bg-black-900_01 py-[52px] lg:gap-[88px] md:gap-[66px] md:py-5 sm:gap-11 sm:py-4">
        <FrameoneRow />
        <FrameoneRowOne />
      </div>
    </>
  );
}
