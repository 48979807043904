import { useState } from "react";
import { Button, Text, Input, Heading, Img } from "../../components";
import React from "react";
import "./FramefourColumnTwo.css";

export default function FramefourColumnTwo() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleRegister = async () => {
    if (!acceptedTerms) {
      setErrorMessage("Você deve aceitar os termos e condições.");
      return;
    }

    if (password !== passwordConfirmation) {
      setErrorMessage("As senhas não coincidem.");
      return;
    }

    try {
      const response = await fetch("http://localhost:8000/api/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
          password_confirmation: passwordConfirmation,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Erro ao realizar o cadastro.");
      }

      setSuccessMessage("Cadastro realizado com sucesso!");
      setErrorMessage("");
    } catch (error) {
      setErrorMessage(error.message);
      setSuccessMessage("");
    }
  };

  return (
    <div className="flex flex-col items-center">
      <div className="container-xs flex flex-col items-center gap-[100px] lg:gap-[100px] lg:px-5 md:gap-[75px] md:px-5 sm:gap-[50px]">
        <div className="flex flex-col items-center gap-[54px] self-stretch rounded-[30px] bg-gray-50 p-[52px] shadow-xs md:p-5 sm:gap-[27px] sm:p-4">
          <div className="mx-1.5 flex flex-col items-center gap-[15px] md:mx-0 sm:flex-col">
            <Img
              src="images/img_union.png"
              alt="Union"
              className="h-[36px] object-cover sm:hidden sm:mx-auto"
            />
            <Heading
              size="heading4xl"
              as="h1"
              className="text-[32px] font-bold tracking-[0.32px] title-text lg:text-[27px] md:text-[26px] sm:text-[24px]"
            >
              Seus dados de acesso
            </Heading>
          </div>

          <div className="mb-3.5 flex w-[92%] flex-col items-center gap-[62px] lg:w-full md:w-full sm:gap-[31px]">
            <div className="flex flex-col gap-[30px] self-stretch">
              <div className="flex flex-col items-start gap-5">
                <Text as="p" className="text-[18px] font-normal tracking-[0.18px] text-black lg:text-[15px]">
                  E-mail
                </Text>
                <Input
                  shape="round"
                  type="email"
                  name="email"
                  placeholder="Digite seu E-mail"
                  className="self-stretch input-transparent"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="flex flex-col items-start gap-5">
                <Text as="p" className="text-[18px] font-normal tracking-[0.18px] text-black lg:text-[15px]">
                  Senha
                </Text>
                <Input
                  shape="round"
                  type="password"
                  name="senha_one"
                  placeholder="Senha"
                  className="self-stretch input-transparent"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className="flex flex-col items-start gap-5">
                <Text as="p" className="text-[18px] font-normal tracking-[0.18px] text-black lg:text-[15px]">
                  Redigite a senha
                </Text>
                <Input
                  shape="round"
                  type="password"
                  name="confirme_a"
                  placeholder="Confirme a senha"
                  className="self-stretch input-transparent"
                  value={passwordConfirmation}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                />
              </div>
            </div>

            <div className="custom-checkbox-container">
              <input
                type="checkbox"
                id="customCheckmark"
                className="custom-checkbox"
                checked={acceptedTerms}
                onChange={() => setAcceptedTerms(!acceptedTerms)}
              />
              <label htmlFor="customCheckmark">
                Eu aceito os termos e condições
              </label>
            </div>

            <Text as="p" className="text-[18px] font-normal tracking-[0.18px] text-blue-900 lg:text-[15px] flex flex-row items-center gap-1">
              <a href="/termos" className="text-blue-900 underline">Termos e condições</a>
              <span className="text-blue_gray-900_01">|</span>
              <a href="/politica" className="text-blue-900 underline">Política de Privacidade</a>
            </Text>
          </div>

          {errorMessage && (
            <p className="text-red-500 text-sm mt-2">{errorMessage}</p>
          )}
          {successMessage && (
            <p className="text-green-500 text-sm mt-2">{successMessage}</p>
          )}
        </div>

        <div className="flex gap-[30px] sm:gap-[15px]">
          <Button
            variant="fill"
            shape="round"
            className="min-w-[170px] sm:min-w-[120px] rounded-[10px] px-[34px] sm:px-[20px] font-bold tracking-[0.16px] sm:px-4"
          >
            Anterior
          </Button>
          <Button
            shape="round"
            className="min-w-[170px] sm:min-w-[120px] rounded-[10px] px-[34px] sm:px-[20px] font-bold tracking-[0.16px] sm:px-4 animated-gradient-button"
            onClick={handleRegister}
          >
            Finalizar
          </Button>
        </div>
      </div>
    </div>
  );
}
