import React from "react";
import { useRoutes } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import CAPA from "pages/CAPA";
import ExperinciadoCliente from "pages/ExperinciadoCliente";
import TESTES from "pages/TESTES";
import TESTESOne from "pages/TESTESOne";
import TESTESTwo from "pages/TESTESTwo";
import InstagrampostOne from "pages/InstagrampostOne";
import InstagrampostOneOne from "pages/InstagrampostOneOne";
import FrameOne from "pages/FrameOne";
import Desktop from "pages/Desktop";
import FrameFive from "pages/FrameFive";
import FrameFour from "pages/FrameFour";
import FrameTwo from "pages/FrameTwo";
import FrameThree from "pages/FrameThree";
import FrameOneOne from "pages/FrameOneOne";
import DeskTwo from "pages/DeskTwo";

const ProjectRoutes = () => {
  let element = useRoutes([
    { path: "/", element: <Home /> },
    { path: "*", element: <NotFound /> },
    {
      path: "capa",
      element: <CAPA />,
    },
    {
      path: "experinciadocliente",
      element: <ExperinciadoCliente />,
    },
    {
      path: "testes",
      element: <TESTES />,
    },
    {
      path: "testesone",
      element: <TESTESOne />,
    },
    {
      path: "testestwo",
      element: <TESTESTwo />,
    },
    {
      path: "instagrampostone",
      element: <InstagrampostOne />,
    },
    {
      path: "instagrampostoneone",
      element: <InstagrampostOneOne />,
    },
    {
      path: "frameone",
      element: <FrameOne />,
    },
    {
      path: "desktop",
      element: <Desktop />,
    },
    {
      path: "framefive",
      element: <FrameFive />,
    },
    {
      path: "framefour",
      element: <FrameFour />,
    },
    {
      path: "frametwo",
      element: <FrameTwo />,
    },
    {
      path: "framethree",
      element: <FrameThree />,
    },
    {
      path: "frameoneone",
      element: <FrameOneOne />,
    },
    {
      path: "desktwo",
      element: <DeskTwo />,
    },
  ]);

  return element;
};

export default ProjectRoutes;
