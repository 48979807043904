import { Helmet } from "react-helmet";
import { Img } from "../../components";
import React from "react";

export default function InstagrampostOneOnePage() {
  return (
    <>
      <Helmet>
        <title>Cainan Lucas Arajo&#39;s Application9</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full flex-col items-center justify-center bg-white-a700 px-14 py-[328px] md:p-5">
        <Img
          src="images/img_camada_2.svg"
          alt="Camadatwo"
          className="mx-auto h-[422px] w-full max-w-[654px] object-contain md:h-auto"
        />
      </div>
    </>
  );
}
