import { Helmet } from "react-helmet";
import { Text, Img } from "../../components";
import DesktwoRowOne from "./DesktwoRowOne";
import React from "react";

export default function DeskTwoPage() {
  return (
    <>
      <Helmet>
        <title>Cainan Lucas Arajo&#39;s Application9</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="relative h-[2066px] w-full bg-white-a700 px-[68px] py-[18px] lg:px-8 md:px-5 sm:px-4">
        <Img
          src="images/img_television.svg"
          alt="Television"
          className="absolute left-[4%] top-[19px] m-auto h-[46px] w-[4%] object-contain"
        />
        <div className="absolute left-0 right-0 top-[3%] m-auto flex w-[93%] flex-col items-center gap-[98px] lg:gap-[98px] md:gap-[73px] sm:gap-[49px]">
          <DesktwoRowOne />
          <div className="container-xs flex flex-col items-center px-14 lg:px-5 md:px-5">
            <div className="flex w-[96%] flex-col gap-5 lg:w-full md:w-full">
              <div className="rowtelegramfree_border flex justify-center rounded-[10px] p-2 shadow-3xl">
                <Text
                  as="p"
                  className="self-end text-[18px] font-medium tracking-[0.18px] text-blue_gray-900_01 lg:text-[15px]"
                >
                  Telegram FREE
                </Text>
              </div>
              <div className="rowtelegramfree_border flex justify-center rounded-[10px] p-2 shadow-3xl">
                <Text as="p" className="text-[18px] font-medium tracking-[0.18px] text-blue_gray-900_01 lg:text-[15px]">
                  Telegram VIP
                </Text>
              </div>
              <div className="relative h-[44px] content-end lg:h-auto md:h-auto">
                <Text
                  as="p"
                  className="mx-auto mb-2 text-[18px] font-medium tracking-[0.18px] text-blue_gray-900_01 lg:text-[15px]"
                >
                  Onlyfans
                </Text>
                <div className="rowtelegramfree_border absolute bottom-0 left-0 right-0 top-0 m-auto h-[44px] flex-1 rounded-[10px] shadow-3xl" />
              </div>
              <div className="relative h-[44px] content-end lg:h-auto md:h-auto">
                <a href="Privacy" target="_blank" rel="noreferrer" className="mx-auto mb-2 lg:text-[15px]">
                  <Text as="p" className="text-[18px] font-medium tracking-[0.18px] text-blue_gray-900_01">
                    Privacy
                  </Text>
                </a>
                <div className="rowtelegramfree_border absolute bottom-0 left-0 right-0 top-0 m-auto h-[44px] flex-1 rounded-[10px] shadow-3xl" />
              </div>
              <div className="relative h-[44px] content-center lg:h-auto md:h-auto">
                <Text
                  as="p"
                  className="mx-auto text-[18px] font-medium tracking-[0.18px] text-blue_gray-900_01 lg:text-[15px]"
                >
                  FanFever
                </Text>
                <div className="rowtelegramfree_border absolute bottom-0 left-0 right-0 top-0 m-auto h-[44px] flex-1 rounded-[10px] shadow-3xl" />
              </div>
              <div className="relative h-[44px] content-end lg:h-auto md:h-auto">
                <Text
                  as="p"
                  className="mx-auto mb-2 text-[18px] font-medium tracking-[0.18px] text-blue_gray-900_01 lg:text-[15px]"
                >
                  Pepper
                </Text>
                <div className="rowtelegramfree_border absolute bottom-0 left-0 right-0 top-0 m-auto h-[44px] flex-1 rounded-[10px] shadow-3xl" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
