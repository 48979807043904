import { Helmet } from "react-helmet";
import { Button, Heading, Text, Input, Img } from "../../components";
import { CloseSVG } from "../../components/Input/close.jsx";
import TestestwoColumn from "./TestestwoColumn";
import TestestwoColumnmorena from "./TestestwoColumnmorena";
import TestestwoRowFive from "./TestestwoRowFive";
import TestestwoRowTwo from "./TestestwoRowTwo";
import TestestwoRowview from "./TestestwoRowview";
import React from "react";

export default function TESTESTwoPage() {
  const [searchBarValue2, setSearchBarValue2] = React.useState("");
  const sliderState = null; // placeholder for slider state
  const sliderRef = null; // placeholder for slider reference

  return (
    <>
      <Helmet>
        <title>Cainan Lucas Arajo&#39;s Application9</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full flex-col gap-7 bg-white-a700">
        <header className="flex flex-col gap-7">
          <div className="flex justify-center bg-gray-800 py-1.5">
            <div className="container-xs flex items-center justify-center gap-2 self-end px-14 lg:px-5 md:px-5">
              <a href="#">
                <Img src="images/img_fi_535234.svg" alt="Fi535234" className="h-[20px] w-[20px]" />
              </a>
              <Text size="textmd" as="p" className="text-[14px] font-normal tracking-[1.68px] text-white-a700">
                14678 Visualizadores
              </Text>
            </div>
          </div>
          <div className="mx-[336px] flex items-start justify-between gap-5 md:mx-0 md:flex-col">
            <Img src="images/img_header_logo.svg" alt="Headerlogo" className="mb-2 h-[50px] w-[78px] object-contain" />
            <div className="flex w-[88%] items-center justify-between gap-5 self-end md:w-full md:flex-col md:self-auto md:px-5">
              <Input
                color="gray_300_02"
                size="xs"
                variant="fill"
                name="search"
                placeholder="Buscar modelos"
                value={searchBarValue2}
                onChange={(e) => setSearchBarValue2(e.target.value)}
                suffix={
                  <div className="flex h-[16px] w-[16px] items-center justify-center">
                    {searchBarValue2?.length > 0 ? (
                      <CloseSVG onClick={() => setSearchBarValue2("")} height={16} width={16} />
                    ) : (
                      <Img src="images/img_search.svg" alt="Search" className="h-[16px] w-[16px]" />
                    )}
                  </div>
                }
                className="w-[24%] gap-4 self-start rounded-[16px] tracking-[0.14px] text-gray-700 md:w-full md:self-auto"
              />
              <ul className="flex items-start gap-10 sm:flex-col">
                <li>
                  <a href="#">
                    <Text
                      size="textmd"
                      as="p"
                      className="text-[14px] font-normal tracking-[1.68px] text-blue_gray-900_02"
                    >
                      MULHERES
                    </Text>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div className="flex flex-col items-start gap-2">
                      <Text
                        size="textmd"
                        as="p"
                        className="text-[14px] font-normal tracking-[1.68px] text-blue_gray-900_02"
                      >
                        CASAIS
                      </Text>
                      <div className="ml-2 h-[4px] w-[40px] bg-gray-500 md:ml-0" />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div className="flex flex-col items-center gap-2">
                      <Text
                        size="textmd"
                        as="p"
                        className="text-[14px] font-normal tracking-[1.68px] text-blue_gray-900_02"
                      >
                        TRANS
                      </Text>
                      <div className="mx-1.5 h-[4px] w-[40px] bg-gray-500 md:mx-0" />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div className="flex flex-col items-center gap-2">
                      <Text
                        size="textmd"
                        as="p"
                        className="text-[14px] font-normal tracking-[1.68px] text-blue_gray-900_02"
                      >
                        HOMENS
                      </Text>
                      <div className="mx-3.5 h-[4px] w-[40px] bg-gray-500 md:mx-0" />
                    </div>
                  </a>
                </li>
              </ul>
              <div className="flex w-[12%] flex-col items-center gap-0.5 md:w-full">
                <Heading
                  size="textxs"
                  as="p"
                  className="text-[9.3px] font-medium tracking-[0.09px] text-blue_gray-900_01"
                >
                  Você é criador(a) de conteúdo?
                </Heading>
                <Button
                  size="xs"
                  shape="round"
                  color="purple_A200_cyan_A100"
                  className="mx-3 self-stretch rounded-lg px-5 font-bold tracking-[0.09px] md:mx-0"
                >
                  CRIE SEU PERFIL
                </Button>
              </div>
            </div>
          </div>
        </header>
        <div className="flex flex-col gap-[66px] sm:gap-[33px]">
          <div className="mx-[316px] md:mx-0">
            <div className="flex flex-col gap-11">
              <div className="relative h-[580px] content-center lg:h-auto md:h-auto">
                <TestestwoColumn />
                <div className="absolute bottom-[11%] right-[7%] m-auto flex items-center justify-center">
                  {[...Array(5)].map((_, i) => (
                    <div
                      key={i}
                      onClick={() => {
                        if (sliderRef && sliderRef.current) {
                          sliderRef.current.slideTo(i * (sliderRef.current.state.itemsInSlide || 1));
                        }
                      }}
                      className="mr-[15px] inline-block h-[4px] w-[40px] cursor-pointer bg-gray-500"
                    />
                  ))}
                </div>
              </div>
              <TestestwoRowTwo />
            </div>
          </div>
          <div>
            <TestestwoRowFive />
            <TestestwoColumnmorena />
            <TestestwoRowview />
          </div>
        </div>
      </div>
    </>
  );
}
