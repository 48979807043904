import React from "react";

const sizes = {
  texts: "text-[11px] font-normal not-italic",
  textmd: "text-[14px] font-normal not-italic",
  textlg: "text-[15px] font-light",
  textxl: "text-[16px] font-normal not-italic lg:text-[13px]",
  text2xl: "text-[17px] font-normal not-italic lg:text-[14px]",
  text3xl: "text-[18px] font-normal not-italic lg:text-[15px]",
  text4xl: "text-[19px] font-light lg:text-[16px]",
  text6xl: "text-[44px] font-normal lg:text-[37px] md:text-[40px] sm:text-[34px]",
};

const Text = ({ children, className = "", as, size = "text3xl", ...restProps }) => {
  const Component = as || "p";

  return (
    <Component className={`text-blue_gray-900_01 font-inter ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Text };
